import React, { useState, useHook, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import facebook_icon from '../images/facebook_icon.svg';
import instagram_icon from '../images/instagram_icon.svg';
import cards from '../images/cards_all.svg';
import stores from '../images/stores.png';

import { useTranslation } from "react-i18next";
import "../translations/i18n";

const Footer = (props) => {

    const { t } = useTranslation();
    const inputM = useRef(null);

    const login = () => {
        alert(inputM.current.value)
    }

    return (
        <div>
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <h3 data-target="#collapse_1">Entreprise</h3>
                            <div className="collapse dont-collapse-sm links" id="collapse_1">
                                <ul>
                                    <li><a href="/about">A propos de nous</a></li>
                                    <li><a href="/contact">Contactez nous</a></li>
                                    <li><a href="/tell-us-everything">Dites-nous tout</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h3 data-target="#collapse_2">Liens Rapides</h3>
                            <div className="collapse dont-collapse-sm links" id="collapse_2">
                                <ul>
                                    <li><a href="cgv">CGV</a></li>
                                    <li><a href="privacy">Politique de confidentialité</a></li>
                                    <li><a href="faq">FAQ</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h3 data-target="#collapse_3">Nos Coordonnées</h3>
                            <div className="collapse dont-collapse-sm contacts" id="collapse_3">
                                <ul>
                                    <li><i class="icon_house_alt"></i>11 rue des Pyrénées <br />91090 Lisses, France</li>
                                    <li><i className="icon_mobile"></i>01 81 85 07 35</li>
                                    <li><i className="icon_mail_alt"></i><a href="mailto:contact@behalal.fr">contact@behalal.fr</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <h3 data-target="#collapse_4">inscrivez vous à notre newsletter</h3>
                            <div class="collapse dont-collapse-sm" id="collapse_4">
                                <div id="newsletter">
                                    <div id="message-newsletter"></div>
                                    <form method="post" action="assets/newsletter.php" name="newsletter_form" id="newsletter_form">
                                        <div class="form-group">
                                            <input type="email" name="email_newsletter" id="email_newsletter" class="form-control" placeholder="Entrez votre adresse email" />
                                            <button type="submit" id="submit-newsletter"><i class="arrow_carrot-right"></i></button>
                                        </div>
                                    </form>
                                </div>
                                <div class="follow_us">
                                    <ul>
                                        <li><a href="https://www.facebook.com/Be-Halal-103746578512759/" target="_blank"><img src={facebook_icon} data-src={facebook_icon} alt="" class="lazy" /></a></li>
                                        <li><a href="https://www.instagram.com/b.ehalal/" target="_blank"><img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" data-src={instagram_icon} alt="" class="lazy" /></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                    <hr />
                    <div className="row add_bottom_25">
                        <div className="col-lg-6">
                            <ul className="footer-selector clearfix">
                                <li>
                                    <div className="styled-select lang-selector">
                                        <select>
                                            <option value="French" selected>French</option>
                                            <option value="English">English</option>
                                        </select>
                                    </div>
                                </li>
                                <li style={{ display: 'none' }}><img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" data-src={cards} alt="" width="198" height="30" className="lazy" /></li>

                            </ul>
                        </div>

                        <div className="col-lg-6">
                            <ul className="additional_links">
                                <li><a href="cgv">CGV</a></li>
                                <li><span>© 2021 B.e Halal</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>

            <div id="toTop"></div>

            <div className="layer"></div>

            <div id="sign-in-dialog" className="zoom-anim-dialog mfp-hide">
                <div className="modal_header">
                    <h3>{t("sign_in")}</h3>
                </div>

                <div className="sign-in-wrapper">

                    <a href="#0" className="social_bt facebook" style={{ display: 'none' }}>Login with Facebook</a>
                    <a href="#0" className="social_bt google" style={{ display: 'none' }}>Login with Google</a>
                    <div className="divider" style={{ display: 'none' }}><span>Or</span></div>
                    <div className="form-group">
                        <label>{t('mobile_number')}</label>
                        <input type="number" ref={inputM} className="form-control" name="mobile" id="mobile" min="9" max="9" />
                        <i className="icon_phone"></i>
                    </div>
                    <div className="form-group" style={{ display: 'none' }}>
                        <label>Password</label>
                        <input type="password" className="form-control" name="password" id="password" value="" />
                        <i className="icon_lock_alt"></i>
                    </div>
                    <div className="clearfix add_bottom_15">
                        <div className="checkboxes float-left">
                            <label className="container_check">{t('rebember')}
                                <input type="checkbox" />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                        <div className="float-right mt-1"><a id="forgot" href="javascript:void(0);">{t('forgot')}</a></div>
                    </div>
                    <div className="text-center">
                        <button className="btn_1 full-width mb_5 one">{t('login')}</button>
                        {t('dontaccount')} <a href="account.html">{t('signup')}</a>

                    </div>
                    <div id="forgot_pw">
                        <div className="form-group">
                            <label>Please confirm login email below</label>
                            <input type="email" className="form-control" name="email_forgot" id="email_forgot" />
                            <i className="icon_mail_alt"></i>
                        </div>
                        <p>You will receive an email containing a link allowing you to reset your password to a new preferred one.</p>
                        <div className="text-center"><input type="submit" value="Reset Password" className="btn_1" /></div>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default Footer;
export const TRANSLATIONS_FR = {
    currency_symbol : '€',
    sign_in:"S'identifier",
    mobile_number: "Numéro de portable",
    rebember: "Souviens-toi de moi",
    forgot: "Mot de passe oublié ?",
    login: "Connexion",
    dontaccount: "Vous n'avez pas de compte ?",
    signup: "S'inscrire",
    verify: "Vérification",
    sent_msg: "Nous venos de vous envoyer un mot de passe",
    enter_pass : "Entrez votre mot de passe",
    otp_error: "OTP non valide",
    set_pincode: "Définir le code PIN",
    pincode: "Code PIN",
    save: "Sauvegarder",
    delivery_not : "La livraison n'est pas disponible pour ce code PIN",
    pincodehelp: "Vous avez empêché BeHalal de suivre votre position. Pour l'utiliser, modifiez votre emplacement.",
    reviews : "Commentaires",
    details : "Des détails",
    instock: "En Stock",
    addcart: "Ajouter au panier",
    addwishlist: 'Ajouter à la liste de souhaits',
    related_products: 'Produits Connexes',
    add: 'Ajouter',
    wonderful:'Merveilleux!',
    success_cart: '## ajouté avec succès à votre panier',
    remove: 'Supprimer',
    removed: 'Supprimé',
    removed_cart: 'Supprimé du panier avec succès',
    areyou: 'Êtes-vous sûr?',
    yes: 'Oui',
    no: 'Non',
    out_stock: 'En rupture de stock',
    my_cart: 'Mon panier',
    your_cart: 'Les articles de votre panier',
    product_name: 'Nom du produit',
    unit_price: 'Prix ​​unitaire',
    quantity: 'Quantité',
    checkout: 'Passer à la caisse',
    continue_shopping: 'Continuer vos achats',
    no_items: "Il n'y a pas d'articles dans le panier",
    delivery_address: "Adresse de livraison",
    add_address: "Ajoutez l'adresse",
    delivery_schedule: "Calendrier de livraison",
    order_summary: "Récapitulatif de la commande",
    delivery_charge: "Frais de livraison",
    total_price: "Prix ​​total",
    place_order: "Passer la commande",
    payment_success: "Paiement effectué avec succès.",
    booking_summary: "Résumé de la réservation",
    date: "Date",
    time_slot: "Créneau horaire",
    order_details: "Détails de la commande",
    payment_failed: 'Paiement échoué',
    order_id: 'Numéro de commande',
    category_product_not: "Produits de la catégorie introuvables",
    products: "des produits",
    my_orders: "Mes Commandes",
    order_no: "N ° de commande",
    order_date: "Date de commande",
    order_status: "Statut de la commande",
    order_timeslot: "Plage horaire de la commande",
    payment_status: "Statut de paiement",
    dashboard: "Tableau de bord",
    my_account: "Mon compte",
    my_shipping_address: "Mon adresse de livraison",
    my_wishlist: "Ma liste d'envies",
    logout: "Se déconnecter",
    first_name: "Prénom",
    last_name: "Nom de famille",
    email: "E-mail",
    save: "Sauvegarder",
    edit: "Éditer",
    my_tickets: "Mes billets",
    open: "Ouvert",
    onhold: "En attente",
    closed: "Fermé"
};
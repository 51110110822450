import React, { useState, useHook, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Header from "../components/Header"
import Footer from "../components/Footer"
import ProductView from "../components/ProductView"
import { productDetails, cart } from '../actions/index';
import '../styles/detail-page.css';
import '../styles/css_skelton.css';
import Cookies from 'js-cookie';
import { get, post } from "../adapters/index";
import { useTranslation } from "react-i18next";
import "../translations/i18n";
import { useParams } from 'react-router';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Cache, { setCache } from '../utility/Cache';
import addCart,{isExist, removeCart} from '../utility/Cart';
import veg from '../images/veg.png';
import non_veg from '../images/nonveg.png';
import userImage from '../images/user.png';
import moment from 'moment';

import jBox from 'jbox';
import 'jbox/dist/jBox.all.css';

const ProductDetails = (props) => {
	const { t } = useTranslation();
	const pDetails = useSelector(state => state.products.details)
	const [addToCart, setAddToCart] = useState(false);
	const userData = useSelector(state => state.user)
	const dispatch = useDispatch();
	const [itemId, setItemId] = useState(0);  
	const { id: productId } = useParams();
	const [loading, setLoading] = useState(1);

	useEffect(function () {
		var CacheData = Cache('pdetails_' + productId);
		setAddToCart(isExist(productId));
		
		CacheData.then((data) => {
			if (data == false)
				_loadDetails();
			else {
				dispatch(productDetails(data.data));
				setLoading(2);
			}
		})

		if (Cookies.get('bhId') == undefined) {
            Cookies.set('bhId', process.env.REACT_APP_DEFAULT_ID, { expires: 2 });
        }

		new jBox('Tooltip', {delayOpen: 100,delayClose: 100,attach: '.tooltip3', pointer: 'right:60',onCreated: function () {
            this.setContent("<div></div>");
        }, getTitle: 'data-jbox-title'});

	}, []);

	const add2Cart = () => {
        addCart(pDetails.id, pDetails.name);
        setAddToCart(true);
        dispatch(cart(1))

		if (Cookies.get('bhUser') != undefined) {
            const form = new FormData();
            form.append('butcher_id', Cookies.get('bhId'));
            form.append('product_id', pDetails.id);
            form.append('product_qty', 1);

            post('cart/store', form).then(async function (response) {
				var item_id = response.data.item_id;
				setItemId(item_id);
                if (Cookies.get('bhCartId') == undefined) {
					
                    get('cart/id').then(async function (response) {
                        if (response.data.data != 0)
                            Cookies.set('bhCartId', response.data.data, { expires: 2 });
                    }).catch(function (error) {});
                }
            }).catch(function (error) {

            });
        }
    }

	const remove2Cart = () => {
        removeCart(pDetails.id, pDetails.name);
        setAddToCart(false);
        dispatch(cart(-1));

		if (Cookies.get('bhUser') != undefined && Cookies.get('bhCartId') != undefined) {
            const form = new FormData();
            form.append('butcher_id', Cookies.get('bhId'));
            form.append('cart_id', Cookies.get('bhCartId'));
            form.append('item_id', itemId);

            post('cart/delete_item', form).then(async function (response) {
                //setItems(response.data.data);
            }).catch(function (error) {

            });
        }
    }

	const _loadDetails = () => {
		setLoading(1);
		const form = new FormData();
		form.append('butcher_id', Cookies.get('bhId'));
		form.append('product_id', productId);

		post('wb/products/details', form).then(async function (response) {
			dispatch(productDetails(response.data.data));
			setCache('pdetails_' + productId, response.data.data);
			setLoading(2);
		}).catch(function (error) {

		});
	}



	return (
		<div>

			<Header />

			<main>

				<div class="container margin_detail">
					<div class="row">
						<div class="col-lg-8">
							<div class="detail_page_head clearfix">

								<div class="title">
									
									<h1 style={{display: loading == 1 ? 'none' : 'block'}}><span>
										{
											pDetails.type == 1
												?
												<img src={veg} alt="" />
												:
												<img src={non_veg} alt="" />
										}

									</span>{' ' + pDetails.name} {' ' + pDetails.qty_type}</h1>
									<div style={{display: loading == 1 ? 'block' : 'none'}}>
										<div style={{width:24, height: 24, float:'left',marginRight: 10}} className="skeleton-loader"></div>
										<div style={{width:200, height: 24, float:'left'}} className="skeleton-loader"></div>
									</div>
		                    		&nbsp;
								</div>
								<div class="rating">
									<div class="score"><span>Superb<em>{pDetails.rated} {t('reviews')}</em></span><strong>{pDetails.rating}</strong></div>
								</div>
							</div>
							{
								pDetails.images != undefined
									?
									<OwlCarousel items={1} dots={true} className='owl-theme magnific-gallery' margin={10} >
										{
											pDetails.images.map((i, k) =>
												<div class="item">
													<a href="#" title={pDetails.name} data-effect="mfp-zoom-in"><img src={process.env.REACT_APP_IMG_URL + i.image} alt="" /></a>
												</div>
											)
										}
									</OwlCarousel>
									:
									<OwlCarousel items={1} dots={true} className='owl-theme magnific-gallery' margin={10} >
										<div style={{width:'100%', height: 400, float:'left'}} className="skeleton-loader"></div>
									</OwlCarousel>
							}

							<div class="tabs_detail">
								<ul class="nav nav-tabs" role="tablist">
									<li class="nav-item">
										<a id="tab-A" href="#pane-A" class="nav-link active" data-toggle="tab" role="tab">Information</a>
									</li>
									<li class="nav-item">
										<a id="tab-B" href="#pane-B" class="nav-link" data-toggle="tab" role="tab">{t('reviews')}</a>
									</li>
								</ul>

								<div class="tab-content" role="tablist">
									<div id="pane-A" class="card tab-pane fade show active" role="tabpanel" aria-labelledby="tab-A">
										<div class="card-header" role="tab" id="heading-A">
											<h5>
												<a class="collapsed" data-toggle="collapse" href="#collapse-A" aria-expanded="true" aria-controls="collapse-A">
													{t('details')}
												</a>
											</h5>
										</div>
										<div id="collapse-A" class="collapse" role="tabpanel" aria-labelledby="heading-A">
											<div class="card-body info_content">
												<p>
													{pDetails.description}
												</p>
											</div>
										</div>
									</div>

									<div id="pane-B" class="card tab-pane fade" role="tabpanel" aria-labelledby="tab-B">
										<div class="card-header" role="tab" id="heading-B">
											<h5>
												<a class="collapsed" data-toggle="collapse" href="#collapse-B" aria-expanded="false" aria-controls="collapse-B">
													{t('reviews')}
												</a>
											</h5>
										</div>
										<div id="collapse-B" class="collapse" role="tabpanel" aria-labelledby="heading-B">
											<div class="card-body reviews">
												<div class="row add_bottom_45 d-flex align-items-center">
													<div class="col-md-3">
														<div id="review_summary">
															<strong>{pDetails.rating}</strong>
															<em>Superb</em>
															<small>Based on {pDetails.rated} reviews</small>
														</div>
													</div>

												</div>

												<div id="reviews">
												{	
													pDetails.ratings != undefined
														?
														pDetails.ratings.map((r, k) =>
															<div class="review_card">
																<div class="row">
																	<div class="col-md-2 user_info">
																		<figure><img src={userImage} alt="" /></figure>
																		<h5>{r.fname}</h5>
																	</div>
																	<div class="col-md-10 review_content">
																		<div class="clearfix add_bottom_15">
																			<span class="rating">{r.rating}<small>/5</small> <strong>Rating average</strong></span>
																			<em>{moment(r.created_at).fromNow()}</em>
																		</div>
																		<p>{r.comment}</p>
																	</div>
																</div>
															</div>
														)
														:
														null
													}

												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="col-lg-4" id="sidebar_fixed">
							<div class="box_booking">
								
							<h5>{t('related_products')}</h5><hr />
							<div className="row">
								{
									pDetails.similar_products != undefined
										?
										
										pDetails.similar_products.map((s, k) =>
											<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
												<ProductView data={s} key={k} />
											</div>
										)

										:
										null
								}
							</div>

								<div style={{ padding: 20, display: 'none' }}>
									<h5>€ {pDetails.unit_price}</h5>
									{
										loading === 2
										?
										pDetails.stock > 0
											?
											<div class="product-available in-stock"><span class="stock">{t('instock')}</span></div>
											:
											<span style={{ color: '#f30' }}>{t('out_stock')}</span>
										:
										null
									}

									<br />
									<div>
										{
											pDetails.description != undefined
												?
												pDetails.description.substring(0, 100)
												:
												null
										}...
									</div>

									<br /><br /><br />
									{
										pDetails.stock > 0
											?
											addToCart == false
												?
												<a onClick={(e) => add2Cart()} href="#" class="btn_1 full-width mb_5">{t('addcart')}</a>
												:
												<span onClick={(e) => remove2Cart()} data-jbox-title={t('areyou')} style={{fontWeight:500,border: '2px solid #111',borderRadius: 3,padding: 5,textAlign: 'center',display: addToCart == true ? 'block' : 'none', cursor: 'pointer'}} className="tooltip3 full-width outline wishlist"><span style={{fontSize:11}} class="fs1" aria-hidden="true" data-icon=""></span> {t('remove')}</span> 
											:
											null
									}
									<br />
									{
										loading === 2
										?
										<a href="wishlist.html" class="btn_1 full-width outline wishlist"><i class="icon_heart"></i> {t('addwishlist')}</a>
										:
										null
									}
									
									<br /><br /><br />
								</div>

							</div>
							<ul class="share-buttons">
								<li><a class="fb-share" href="#0"><i class="social_facebook"></i> Share</a></li>
								<li><a class="twitter-share" href="#0"><i class="social_twitter"></i> Share</a></li>
								<li><a class="gplus-share" href="#0"><i class="social_googleplus"></i> Share</a></li>
							</ul>
						</div>

					</div>

					<div className="row" style={{display: 'none'}}>
						<div className="col-lg-12 col-xs-12 col-sm-12">

							<h5>{t('related_products')}</h5><hr />
							<div className="row">
								{
									pDetails.similar_products != undefined
										?

										pDetails.similar_products.map((s, k) =>
											<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
												<ProductView data={s} key={k} />
											</div>
										)

										:
										null
								}
							</div>
						</div>
					</div>

				</div>

			</main>

			<Footer />
		</div>
	)
}

export default ProductDetails;
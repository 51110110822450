export const TRANSLATIONS_EN = {
    currency_symbol : '€',
    sign_in:"Sign In",
    mobile_number: "Mobile Number",
    rebember: "Remember me",
    forgot: "Forgot Password ?",
    login: "Login",
    dontaccount: "Don’t have an account?",
    signup: "Signup",
    verify: "Verify",
    sent_msg: "We have sent you a password",
    enter_pass : "Enter your password",
    otp_error: "Invalid OTP",
    set_pincode: "Set pincode",
    pincode: "Pincode",
    save: "Save",
    delivery_not : "Delivery is not available to this pincode",
    pincodehelp: "You have blocked BeHalal from tracking your location. To use this, change your location.",
    reviews : "Reviews",
    details : "Details",
    instock: "In Stock",
    addcart: "Add to Cart",
    addwishlist: 'Add to wishlist',
    related_products: 'Related Products',
    add: 'Add',
    remove: 'Remove',
    wonderful:'Wonderful!',
    success_cart: 'Successfully added ## to your cart',
    removed: 'Removed',
    removed_cart: 'Successfully removed from cart',
    areyou: 'Are you sure?',
    yes: 'Yes',
    no: 'No',
    out_stock: 'Out of stock',
    my_cart: 'My Cart',
    your_cart: 'Your Cart Items',
    product_name: 'Product Name',
    unit_price: 'Unit Price',
    quantity: 'Quantity',
    checkout: 'Proceed to checkout',
    continue_shopping: 'Continue Shopping',
    no_items: 'There is no items',
    delivery_address: "Delivery Address",
    add_address: "Add Address",
    delivery_schedule: "Delivery Schedule",
    order_summary: "Order Summary",
    delivery_charge: "Delivery Charge",
    total_price: "Total Price",
    place_order: "Place Order",
    payment_success: "Payment successfully completed.",
    booking_summary: "Booking summary",
    date: "Date",
    time_slot: "Time slot",
    order_details: "Order Details",
    payment_failed: 'Payment failed',
    order_id: 'Order Id',
    category_product_not: "Category products not found",
    products: "Products",
    my_orders: "My Orders",
    order_no: "Order No",
    order_date: "Order Date",
    order_status: "Order Status",
    order_timeslot: "Order Timeslot",
    payment_status: "Payment Status",
    dashboard: "Dashboard",
    my_account: "My Account",
    my_shipping_address: "My Shipping Address",
    my_wishlist: "My wishlist",
    logout: "Logout",
    first_name: "First Name",
    last_name: "Last Name",
    email: "Email",
    save: "Save",
    edit: "Edit",
    my_tickets: "My Tickets",
    open: "Open",
    on_hold: "On Hold",
    closed: "Closed"
};
import React, { useState, useEffect, useRef, useParams } from 'react';
import HeaderHome from "../components/HeaderHome"
import Footer from "../components/Footer"
import '../styles/submit.css';
import '../styles/contacts.css';
import contact from '../images/contact.jpg';
import { store } from 'react-notifications-component';
import 'react-responsive-modal/styles.css';
import Cookies from 'js-cookie';
import { post, get } from "../adapters/index";

const Contact = () => {

    Element.prototype.insertAfter = function(newEl) {
        this.parentNode.insertBefore(newEl, this.nextSibling);
    }

    const contactus = () => {

        var error = false;
        var ele = document.querySelectorAll(".error");
        
        for (var j = 0; j < ele.length; j++) {
            ele[j].remove();
        }

        var name_contact = document.getElementById('name_contact');
        var email_contact = document.getElementById('email_contact');
        var telephone_register = document.getElementById('telephone_register');
        var message_contact = document.getElementById('message_contact');

        if (name_contact.value.length == 0) {
            var newEll = document.createElement("div");
            newEll.classList.add('error');
            newEll.innerHTML = "s'il vous plaît entrez le nom";
            name_contact.insertAfter(newEll);
            error = true;
        }

        if (email_contact.value.length == 0) {
            var newEll = document.createElement("div");
            newEll.classList.add('error');
            newEll.innerHTML = "veuillez entrer l'adresse e-mail";
            email_contact.insertAfter(newEll);
            error = true;
        }

        if (telephone_register.value.length == 0) {
            var newEll = document.createElement("div");
            newEll.classList.add('error');
            newEll.innerHTML = "veuillez entrer le numéro de téléphone";
            telephone_register.insertAfter(newEll);
            error = true;
        }

        if (message_contact.value.length == 0) {
            var newEll = document.createElement("div");
            newEll.classList.add('error');
            newEll.innerHTML = "s'il vous plaît entrer un message";
            message_contact.insertAfter(newEll);
            error = true;
        }
        
        var details = {
            name_contact : name_contact.value,
            email_contact : email_contact.value,
            telephone_register: telephone_register.value,
            message_contact: message_contact.value
        }

        if (error === true) {
            return false;
        }

        const form = new FormData();
        form.append('butcher_id', Cookies.get('bhId'));
        form.append('page', 'contact');
        form.append('details', JSON.stringify(details));

        post('/wb/admin/contactus', form).then(async function (response) {
            name_contact.value = '';
            email_contact.value = '';
            telephone_register.value = '';
            message_contact.value = '';
            alert("Soumis avec succès")
            
        }).catch(function (error) {
        });

    }

    return (
        <div>
            <HeaderHome />
            <div class="hero_single inner_pages background-image" data-background={"url(" + contact + ")"}>
                <div class="opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.6)">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-xl-9 col-lg-10 col-md-8">
                                <h1>Contactez B.e Halal</h1>
                                <p>Appelez-nous, écrivez-nous Ou remplissez le formulaire ci-dessous</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="bg_gray">
                <div class="container margin_60_40">
                    <div class="row justify-content-center">
                        <div class="col-lg-4">
                            <div class="box_contacts">
                                <i class="icon_phone"></i><br /><br />
                                <a href="tel:+330181850735">01 81 85 07 35</a>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="box_contacts">
                                <i class="icon_pin"></i><br /><br />
                                <div>11 rue des Pyrénées, 91090 Lisses, France</div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="box_contacts">
                                <i class="icon_mail"></i><br /><br />
                                <a href="mailto:contact@behalal.fr">contact@behalal.fr</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container margin_60_40">
                <h5 class="mb_5">Formulaire de contact</h5>
                <div class="row">
                    <div class="col-lg-4 col-md-6 add_bottom_25">
                        <div id="message-contact"></div>
                        <form action="javascript:void(0);">
                            <div class="form-group">
                                <input class="form-control" type="text" placeholder="Nom" id="name_contact" name="name_contact" />
                            </div>
                            <div class="form-group">
                                <input class="form-control" type="email" placeholder="Email" id="email_contact" name="email_contact" />
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Numero de telephone" name="telephone_register" id="telephone_register" />
                            </div>


                            <div class="form-group">
                                <textarea class="form-control" style={{height: 150}} placeholder="Message" id="message_contact" name="message_contact"></textarea>
                            </div>
                            <div class="form-group">
                                <input class="btn_1 full-width" type="submit" value="Soumettre" id="submit-contact" onClick={()=>contactus()}/>
                            </div>
                        </form>
                    </div>
                    <div class="col-lg-8 col-md-6 add_bottom_25">
                        <iframe class="map_contact" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2638.104007149018!2d2.429638815665524!3d48.6078523792637!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e5ddf9313c69cf%3A0x37e25ebe9d0e4110!2s11%20Rue%20des%20Pyr%C3%A9n%C3%A9es%2C%2091090%20Lisses%2C%20France!5e0!3m2!1sen!2sin!4v1618632220932!5m2!1sen!2sin" allowfullscreen></iframe>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default Contact;

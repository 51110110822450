import React, { useState, useHook, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Header from "../components/Header"
import Footer from "../components/Footer"
import ProductView from "../components/ProductView"
import { productDetails, cart } from '../actions/index';
import '../styles/detail-page.css';
import Cookies from 'js-cookie';
import { get, post } from "../adapters/index";
import { useTranslation } from "react-i18next";
import "../translations/i18n";
import { useParams } from 'react-router';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Cache, { setCache } from '../utility/Cache';
import addCart, { isExist, removeCart } from '../utility/Cart';
import veg from '../images/veg.png';
import non_veg from '../images/nonveg.png';
import userImage from '../images/user.png';
import moment from 'moment';

import jBox from 'jbox';
import 'jbox/dist/jBox.all.css';

const PaymentSuccess = (props) => {
    const { t } = useTranslation();
    const [total, setTotal] = useState(0);
    const [deliveryCharge, setDeliveryCharge] = useState(0);
    const { id: paymentId } = useParams();

    const [date, setDate] = useState('');
    const [timeSlot, setTimeSlot] = useState('');
    const [orders, setOrders] = useState([]);

    useEffect(function () {
        fetchOrderDetails();
    }, []);

    const fetchOrderDetails = () => {
        const form = new FormData();
        form.append('butcher_id', Cookies.get('bhId'));
        form.append('payment_id', paymentId);

        post('orders/payment', form).then(async function (response) {
            console.table(response.data.data.items);
            setOrders(response.data.data);
            setTotal(response.data.data.total_price);
            setDate(response.data.data.order_date);
            setTimeSlot(response.data.data.time_slot);
            setDeliveryCharge(response.data.data.delivery_charge);
        }).catch(function (error) {});
    }

    return (
        <div>
            <Header />
            <main class="bg_gray pattern">

                <div class="container margin_60_40 margin_mobile">
                    <div class="row justify-content-center">
                        <div class="col-lg-4">
                            <div class="box_booking_2">
                                <div class="head">
                                    <div class="title">
                                        <h3>{t('payment_success')}</h3>
		                                {t('currency_symbol')} {total} 
                                    </div>
                                </div>
                                <div class="main">
                                    <h6>{t('booking_summary')}</h6>
                                    <ul>
                                        <li>{t('date')}<span> {date}</span></li>
                                        <li>{t('time_slot')}<span>{timeSlot}</span></li>
                                    </ul>
                                    <hr />
                                    <h6>{t('order_details')}</h6>

                                    <ul class="clearfix">
                                        {
                                            orders.items != undefined && orders.items.length > 0
                                            ? 
                                            orders.items.map((i, k) =>
                                                <li>
                                                    <a href="#0">{i.product_qty}x {i.product_name}</a><span>{t('currency_symbol')} {i.product_price}</span>
                                                </li>
                                            )
                                            :
                                            null
                                        }
                                        <li style={{marginTop: 30}}>
                                            <a>{t('delivery_charge')}</a>
                                            <span>{t('currency_symbol')} {deliveryCharge}</span>
                                        </li>

                                        <li>
                                            <a>{t('total_price')}</a>
                                            <span>{t('currency_symbol')} {total}</span>
                                        </li>
                                    </ul>

                                    <a href="/" class="btn_1 full-width outline mb_5">{t('continue_shopping')}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </main>
            <Footer />
        </div>
    )
}

export default PaymentSuccess;
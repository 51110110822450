import React, { useState, useHook, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import logo from '../images/logo.svg';
import logo_sticky from '../images/logo_sticky.svg';
import userImage from '../images/user.png';

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import { useTranslation } from "react-i18next";
import "../translations/i18n";

import { useSelector, useDispatch } from 'react-redux';
import { addFav, removeFav, isLogin, loginStep, loading, fingerprint, user, cart } from '../actions/index';

import axios from "axios";
import Cookies from 'js-cookie';

import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

import addCart,{count} from '../utility/Cart';
import {reactLocalStorage} from 'reactjs-localstorage';
import { post } from "../adapters/index";
import moment from 'moment';

const Header = (props) => {

    const inputM = useRef(null);
    const inputO = useRef(null);
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [mobile, setMobile] = useState(0);
    const [error, setError] = useState('');
    const [items, setItems] = useState([]);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    const favorite = useSelector(state => state.favorite);
    const isUserLogin = useSelector(state => state.login);
    const loader = useSelector(state => state.loading);
    const DFinger = useSelector(state => state.fingerprint);
    const userData = useSelector(state => state.user);
    const numOfItems = useSelector(state => state.cart.count);
    const loginOpen = useSelector(state => state.open.open);
    const loginAfter = useSelector(state => state.open.loginAfter);
    const [mobError, setMobError] = useState(0);
    const dispatch = useDispatch();

    useEffect(function () {
        setOpen(loginOpen);
    }, [loginOpen]);

   
    useEffect(function () {
        var c = window.client;
        
        dispatch(fingerprint(c.getFingerprint()))

        if (Cookies.get('bhUser') != undefined) {
            dispatch(user(Cookies.getJSON('bhUser')));
            dispatch(isLogin({ is_login: true, login_step: 2 }));
        }

        dispatch(cart(count()));
    }, []);

    //LOGIN ACTION
    const login = () => {
        setMobError(0);
        var mobile = inputM.current.value;
        
        if (mobile.length != 9) {
            setMobError(1);
            return false;
        }

        dispatch(isLogin({ is_login: false, login_step: 1 }))
        dispatch(loading(true))
        setMobile(mobile);

        const form = new FormData();
        form.append("phone", mobile);
        form.append("player_id", "");
        form.append("refcode", "");

        const options = {
            method: 'POST',
            url: process.env.REACT_APP_API_URL + 'register',
            headers: {
                'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
                Accept: 'application/json',
                Fingerprint: DFinger
            },
            data: form
        };

        dispatch(loading(false))
        return axios.request(options).then(
            //data => dispatch(makeASandwich(forPerson, sauce)),
            //error => dispatch(apologize('The Sandwich Shop', forPerson, error))
        );
    }


    const setLogin = (data) => {
        //SET JS-COOKIE
        var c = { token: data.data.data.customer.api_token, mobile: data.data.data.customer.phone, name: data.data.data.customer.fname + ' ' + data.data.data.customer.lname };
        //document.cookie = "bhUser=1234;max-age=172800; SameSite=Strict; Secure";
        Cookies.set('bhUser', { token: data.data.data.customer.api_token, mobile: data.data.data.customer.phone, name: data.data.data.customer.fname + ' ' + data.data.data.customer.lname }, { expires: 2 });

        //DISPATCH USER VALUES
        dispatch(isLogin({ is_login: true, login_step: 2 }))
        dispatch(user({ token: data.data.data.customer.api_token, mobile: data.data.data.customer.phone, name: data.data.data.customer.fname + ' ' + data.data.data.customer.lname }))
        
        //CLOSE MODAL
        setOpen(false);
        setError('')
        
        //if (loginAfter == 'checkout') {
            //SAVE CART
            saveCart();
            _fetchUserCartData();
        //}
    }

    const _fetchUserCartData = () => {
        const form = new FormData();
		form.append('butcher_id', Cookies.get('bhId'));

		post('cart/view', form).then(async function (response) {
            var cartData = [];
			response.data.data.map((i, k) => 
                cartData.push({
                    product: i[0].id,
                    qty: i[0].cart_qty
                })
            );

            var objectData = {
                data : cartData,
                expiry_time : moment().endOf('day').format("YYYY-MM-DD HH:mm:ss")
            }

            reactLocalStorage.remove('bhCart');
            reactLocalStorage.setObject('bhCart', JSON.stringify(objectData));
		}).catch(function (error) {});
    }

    const saveCart = () => {
        var bhCart = reactLocalStorage.getObject('bhCart');

        if (Object.keys(bhCart).length == 0) {
            return null;
        }

        var cart = JSON.parse(bhCart);
        const form = new FormData();
		form.append('butcher_id', Cookies.get('bhId'));
		form.append('cart', JSON.stringify(cart.data));

		post('cart/bulk/store', form).then(async function (response) {
            if (loginAfter == 'checkout') {
			    window.location = '/checkout';
            }
		}).catch(function (error) {});
    }

    const verify = () => {
        setMobError(0);
        if (inputO.current.value.length != 4) {
            setMobError(100);
            return false;
        }

        const form = new FormData();
        form.append("phone", mobile);
        form.append("otp", inputO.current.value);

        const options = {
            method: 'POST',
            url: process.env.REACT_APP_API_URL + 'verify-otp',
            headers: {
                'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
                Accept: 'application/json',
                Fingerprint: DFinger
            },
            data: form
        };


        return axios.request(options).then(
            data => setLogin(data),
            error => setError(t('otp_error'))
        );

        /*axios.request(options).then(function (response) {
            console.log(response.data);
        }).catch(function (error) {
            console.error(error);
        });*/
    }

    return (
        <header className="header_in clearfix">
            <ReactNotification />
            <div className="container">
                <div id="logo">
                    <a href="/">
                        <img src={logo_sticky} alt="" className="logo_sticky" />
                    </a>
                </div>

                <ul id="top_menu">
                    {
                        isUserLogin.is_login == false || isUserLogin.is_login == undefined
                            ?
                            <li><a onClick={() => setOpen(true)} href="#" id="sign-in" className="login">Sign In</a></li>
                            :
                            <li>
                                <div className="dropdown user clearfix">
                                    <a href="#" data-toggle="dropdown" aria-expanded="false">
                                        <figure><img src={userImage} alt="" /></figure> {userData.name != ' ' ? userData.name : userData.mobile}
                                    </a>
                                    <div className="dropdown-menu">
                                        <div className="dropdown-menu-content">
                                            <ul>
                                                <li><a href="/dashboard"><i className="icon_cog"></i>{t('dashboard')}</a></li>
                                                <li><a href="/my_orders"><i className="icon_document"></i>{t('my_orders')}</a></li>
                                                <li><a href="/logout"><i className="icon_key"></i>Se déconnecter</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                    }

                    <li><a href="/cart" className="wishlist_bt_top" title={t('my_cart')}>{t('my_cart')}</a><span className="badge">{numOfItems}</span></li>
                </ul>
                <a href="#0" className="open_close">
                    <i className="icon_menu"></i><span>Menu</span>
                </a>
                <nav className="main-menu">
                    <div id="header_menu">
                        <a href="#0" className="open_close">
                            <i className="icon_close"></i><span>Menu</span>
                        </a>
                        <a href="index.html"><img src={logo} alt="" /></a>
                    </div>
                    <ul>
                    <li>
					    <a href={process.env.REACT_APP_BASE_URL} class="show-submenu">Menu principal</a>
				        </li>
                        <li>
                            <a href={process.env.REACT_APP_BASE_URL + "about"} className="show-submenu">Qui sommes nous</a>
                        </li>
                        <li>
					        <a href={process.env.REACT_APP_BASE_URL + "contact"} class="show-submenu">Contactez-nous</a>
				        </li>
                        <li>
                            <a href={process.env.REACT_APP_BASE_URL + "tell-us-everything"} className="show-submenu">Dites-nous tout</a>
                        </li>
                        <li>
                            <a href={process.env.REACT_APP_BASE_URL + "become-partner"} className="show-submenu">Devenir membre B.e Halal</a>
                        </li>
                    </ul>
                </nav>
            </div>

            <Modal styles={{ modal: { backgroundColor: 'transparent', overflow: 'hidden',boxShadow: 'none' } }} open={open} showCloseIcon={false} onClose={onCloseModal} blockScroll={true} center>
                {
                    isUserLogin.login_step == 0
                        ?
                        <div id="sign-in-dialog" className="zoom-anim-dialog">
                            <div className="modal_header">
                                <h3>{t("sign_in")}</h3>
                            </div>
                            <div className="sign-in-wrapper">
                                <a href="#0" className="social_bt facebook" style={{ display: 'none' }}>Login with Facebook</a>
                                <a href="#0" className="social_bt google" style={{ display: 'none' }}>Login with Google</a>
                                <div className="divider" style={{ display: 'none' }}><span>Or</span></div>
                                <div className="form-group">
                                    <label>{t('mobile_number')}</label>
                                    <input type="text" ref={inputM} className="form-control" name="mobile" id="mobile" min="9" max="9" />
                                    <i className="icon_phone"></i>
                                    {
                                        mobError === 1 ? <div className="error">Veuillez entrer un numéro de mobile valide</div> : null
                                    }
                                </div>
                                <div className="form-group" style={{ display: 'none' }}>
                                    <label>Password</label>
                                    <input type="password" className="form-control" name="password" id="password" value="" />
                                    <i className="icon_lock_alt"></i>
                                    
                                </div>
                                <div className="clearfix add_bottom_15" style={{display: 'none'}}>
                                    <div className="checkboxes float-left">
                                        <label className="container_check">{t('rebember')}
                                            <input type="checkbox" />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    <div style={{display: 'none'}} className="float-right mt-1"><a id="forgot" href="javascript:void(0);">{t('forgot')}</a></div>
                                </div>
                                <div className="text-center">
                                    <button onClick={() => login()} className="btn_1 full-width mb_5 one">{t('login')}</button>
                                    {t('dontaccount')} <a href="#">{t('signup')}</a>

                                </div>
                                <div id="forgot_pw">
                                    <div className="form-group">
                                        <label>Please confirm login email below</label>
                                        <input type="email" className="form-control" name="email_forgot" id="email_forgot" />
                                        <i className="icon_mail_alt"></i>
                                    </div>
                                    <p>You will receive an email containing a link allowing you to reset your password to a new preferred one.</p>
                                    <div className="text-center"><input type="submit" value="Reset Password" className="btn_1" /></div>
                                </div>
                            </div>

                        </div>
                        :
                        <div id="sign-in-dialog" className="zoom-anim-dialog">
                            <div className="modal_header">
                                <h3>{t("verify")}</h3>
                            </div>
                            <div className="sign-in-wrapper">
                                <p>
                                    {t('sent_msg') + " +33 " + mobile}
                                </p>
                                <div className="form-group">
                                    <label>{t('enter_pass')}</label>
                                    <input type="number" ref={inputO} className="form-control" name="otp" id="otp" min="4" max="4" />
                                    <i className="icon-user_2"></i>
                                    {
                                        mobError === 100 ? <div className="error">Veuillez entrer un code de vérification valide</div> : null
                                    }
                                </div>

                                {
                                    error != ''
                                        ?
                                        <div style={{ color: 'red', fontSize: 12, paddingBottom: 5, paddingTop: 5 }}>
                                            {error}
                                        </div>
                                        :
                                        null
                                }

                                <div className="text-center">
                                    <button onClick={() => verify()} className="btn_1 full-width mb_5 one">Verify</button>
                                </div>
                            </div>
                        </div>
                }

            </Modal>

        </header>
    )
}

export default Header;
import React, { useState, useEffect, useRef, useParams } from 'react';
import '../styles/detail-page.css';
import Cookies from 'js-cookie';
import { post, get } from "../adapters/index";
import { useTranslation } from "react-i18next";
import "../translations/i18n";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import PulseLoader from 'react-spinners/PulseLoader';
import { store } from 'react-notifications-component';
import '../styles/css_skelton.css';
import addCart from '../utility/Cart';

const ShippingAddress = () => {
    const { t } = useTranslation();
    const [error, setError] = useState(0);
    const [loading, setLoading] = useState(0);
    const [address, setAddress] = useState([]);
    const [addForm, setAddForm] = useState(false);
    const [editForm, setEditForm] = useState(false);
    const [editDetails, setEditDetails] = useState({
        deliver_to_fname : '',
        deliver_to_lname : '',
        deliver_to_phone : '',
        address_line1 : '',
        address_line2 : '',
        address_line3 : '',
        landmark : '',
        city : '',
        pincode : '',
        type : 'home',
        id: 0
    });

    useEffect(function () {
        fetchAddress();
    }, []);

    const fetchAddress = () => {
        get('customer/address-list').then(async function (response) {
            setAddress(response.data.data.customeraddress);
        }).catch(function (error) { });
    }

    const alerts = (msg) => {
        store.addNotification({
            title: 'Alerte',
            message: msg,
            type: "danger",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
    }

    const add = () => {
        var first_name = document.getElementById('first_name');
        var last_name = document.getElementById('last_name');
        var phone = document.getElementById('phone');
        var address1 = document.getElementById('address1');
        var address2 = document.getElementById('address2');
        var address3 = document.getElementById('address3');

        var ville = document.getElementById('ville');
        var land_mark = document.getElementById('land_mark');
        var pincode = document.getElementById('pincode');

        if (first_name.value.length == 0) {
            alerts("Entrez s'il vous plait prénom");
            return false;
        }
        else if (last_name.value.length == 0) {
            alerts("Entrez s'il vous plait nom de famille");
            return false;
        }
        else if (phone.value.length == 0 || phone.value.length != 9) {
            alerts("Entrez s'il vous plait numéro de portable");
            return false;
        }
        else if (address1.value.length == 0) {
            alerts("Entrez s'il vous plait adresse ligne 1");
            return false;
        }
        else if (address2.value.length == 0) {
            alerts("Entrez s'il vous plait adresse ligne 2");
            return false;
        }
        else if (address3.value.length == 0) {
            alerts("Entrez s'il vous plait adresse ligne 3");
            return false;
        }
        else if (ville.value.length == 0) {
            alerts("Entrez s'il vous plait ville");
            return false;
        }
        else if (land_mark.value.length == 0) {
            alerts("Entrez s'il vous plait point de repère");
            return false;
        }
        else if (pincode.value.length == 0) {
            alerts("Entrez s'il vous plait code postal");
            return false;
        }

        setLoading(1);
        const form = new FormData();
        form.append("pincode", pincode.value);
        post('customer/validate/pincode', form).then(async function (response) {
            if (response.data.data.pincode == 'invalid') {
                alerts("Désolé, cet article ne peut pas être expédié à l'adresse que vous avez sélectionnée")
                return false;
            }
            else {
                var getSelectedValue = document.querySelector('input[name="type"]:checked');

                const form = new FormData();
                form.append("deliver_to_fname", first_name.value);
                form.append("deliver_to_lname", last_name.value);
                form.append("deliver_to_phone", phone.value);
                form.append("address_line1", address1.value);
                form.append("address_line2", address2.value);
                form.append("address_line3", address3.value);
                form.append("landmark", land_mark.value);
                form.append("city", ville.value);
                form.append("pincode", pincode.value);
                form.append("type", getSelectedValue.value);

                post('customer/add-address', form).then(async function (response) {
                    fetchAddress();
                    setAddForm(false);

                    store.addNotification({
                        title: "Succès",
                        message: "Enregistré avec succès",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                    setLoading(0);
                }).catch(function (error) {
                    setLoading(0);
                    alerts("Valeurs d'adresse non valides")
                    return false;
                 });
            }
        }).catch(function (error) { });
    }

    const edit = () => {
        var first_name = document.getElementById('edit_first_name');
        var last_name = document.getElementById('edit_last_name');
        var phone = document.getElementById('edit_phone');
        var address1 = document.getElementById('edit_address1');
        var address2 = document.getElementById('edit_address2');
        var address3 = document.getElementById('edit_address3');

        var ville = document.getElementById('edit_ville');
        var land_mark = document.getElementById('edit_land_mark');
        var pincode = document.getElementById('edit_pincode');

        if (first_name.value.length == 0) {
            alerts("Entrez s'il vous plait prénom");
            return false;
        }
        else if (last_name.value.length == 0) {
            alerts("Entrez s'il vous plait nom de famille");
            return false;
        }
        else if (phone.value.length == 0 || phone.value.length != 9) {
            alerts("Entrez s'il vous plait numéro de portable");
            return false;
        }
        else if (address1.value.length == 0) {
            alerts("Entrez s'il vous plait adresse ligne 1");
            return false;
        }
        else if (address2.value.length == 0) {
            alerts("Entrez s'il vous plait adresse ligne 2");
            return false;
        }
        else if (address3.value.length == 0) {
            alerts("Entrez s'il vous plait adresse ligne 3");
            return false;
        }
        else if (ville.value.length == 0) {
            alerts("Entrez s'il vous plait ville");
            return false;
        }
        else if (land_mark.value.length == 0) {
            alerts("Entrez s'il vous plait point de repère");
            return false;
        }
        else if (pincode.value.length == 0) {
            alerts("Entrez s'il vous plait code postal");
            return false;
        }

        setLoading(1);
        const form = new FormData();
        form.append("pincode", pincode.value);
        post('customer/validate/pincode', form).then(async function (response) {
            if (response.data.data.pincode == 'invalid') {
                alerts("Désolé, cet article ne peut pas être expédié à l'adresse que vous avez sélectionnée")
                return false;
            }
            else {
                var getSelectedValue = document.querySelector('input[name="edit_type"]:checked');

                const form = new FormData();
                form.append("deliver_to_fname", first_name.value);
                form.append("deliver_to_lname", last_name.value);
                form.append("deliver_to_phone", phone.value);
                form.append("address_line1", address1.value);
                form.append("address_line2", address2.value);
                form.append("address_line3", address3.value);
                form.append("landmark", land_mark.value);
                form.append("city", ville.value);
                form.append("pincode", pincode.value);
                form.append("type", getSelectedValue.value);

                post('customer/update-address/' + editDetails.id, form).then(async function (response) {
                    fetchAddress();
                    setEditForm(false);

                    store.addNotification({
                        title: "Succès",
                        message: "Enregistré avec succès",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                    setLoading(0);
                }).catch(function (error) {
                    setLoading(0);
                    alerts("Valeurs d'adresse non valides")
                    return false;
                });
            }
        }).catch(function (error) { });
    }

    const editFormShow = (address) => {
        setEditDetails(address);
        setEditForm(true);
    }

    const del = (id) => {
        get('customer/delete-address/' + id).then(async function (response) {
            fetchAddress();
            store.addNotification({
                title: "Succès",
                message: "Supprimé avec succès",
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
        }).catch(function (error) { });
    }

    return (
        <div>
            <h5>{t('my_shipping_address')}</h5>
            {
                addForm == false ?
                    <span onClick={() => setAddForm(true)} class="fs1" aria-hidden="true" data-icon="L" style={{ float: 'right', marginTop: -31, cursor: 'pointer' }}> Ajouter </span>
                    :
                    <span onClick={() => setAddForm(false)} class="fs1" aria-hidden="true" data-icon="K" style={{ float: 'right', marginTop: -31, cursor: 'pointer' }}> Fermer </span>
            }

            <hr />

            <div className="row">

                <div class="col-xl-12 col-lg-12" style={{ display: editForm == true ? 'block' : 'none' }}>

                    <div className="row">
                        <div className="col-sm-12 col-xl-4 col-lg-4">
                            <div className="form-group">
                                <label>Prénom</label>
                                <input type="text" className="form-control" name="edit_first_name" id="edit_first_name" defaultValue={editDetails.deliver_to_fname}/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-xl-4 col-lg-4">
                            <div className="form-group">
                                <label>Nom de famille</label>
                                <input type="text" className="form-control" name="edit_last_name" id="edit_last_name" defaultValue={editDetails.deliver_to_lname}/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-xl-4 col-lg-4">
                            <div className="form-group">
                                <label>Numéro de portable</label>
                                <input type="text" className="form-control" name="edit_phone" id="edit_phone" defaultValue={editDetails.deliver_to_phone}/>
                            </div>
                        </div>

                        <div className="col-sm-12 col-xl-12 col-lg-12" style={{ marginTop: 10, marginBottom: 10 }}>
                            <label>Adresse ligne 1</label>
                            <input type="text" className="form-control" name="edit_address1" id="edit_address1" defaultValue={editDetails.address_line1}/>
                        </div>

                        <div className="col-sm-12 col-xl-12 col-lg-12" style={{ marginTop: 10, marginBottom: 10 }}>
                            <label>Adresse ligne 2</label>
                            <input type="text" className="form-control" name="edit_address2" id="edit_address2" defaultValue={editDetails.address_line2}/>
                        </div>

                        <div className="col-sm-12 col-xl-12 col-lg-12" style={{ marginTop: 10, marginBottom: 10 }}>
                            <label>Adresse ligne 3</label>
                            <input type="text" className="form-control" name="edit_address3" id="edit_address3" defaultValue={editDetails.address_line3}/>
                        </div>


                        <div className="col-sm-12 col-xl-4 col-lg-4">
                            <div className="form-group">
                                <label>Ville</label>
                                <input type="text" className="form-control" name="edit_ville" id="edit_ville" defaultValue={editDetails.city} />
                            </div>
                        </div>
                        <div className="col-sm-12 col-xl-4 col-lg-4">
                            <div className="form-group">
                                <label>Point de repère</label>
                                <input type="text" className="form-control" name="edit_land_mark" id="edit_land_mark" defaultValue={editDetails.landmark}/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-xl-4 col-lg-4">
                            <div className="form-group">
                                <label>Code Postal</label>
                                <input type="text" className="form-control" name="edit_pincode" id="edit_pincode" defaultValue={editDetails.pincode}/>
                            </div>
                        </div>

                        <div className="col-sm-12 col-xl-4 col-lg-4">
                            <input defaultChecked={editDetails.type === 'home' ? true : false} type="radio" name="edit_type" value="home" id="maison" /> <label htmlFor="maison"> MAISON </label>
                        </div>

                        <div className="col-sm-12 col-xl-4 col-lg-4">
                            <input defaultChecked={editDetails.type === 'work' ? true : false} type="radio" name="edit_type" value="work" id="travail" /> <label htmlFor="travail">TRAVAIL</label>
                        </div>

                        <div className="col-sm-12 col-xl-4 col-lg-4">
                            <input defaultChecked={editDetails.type === 'other' ? true : false} type="radio" name="edit_type" value="other" id="autre" /> <label htmlFor="autre">AUTRE</label>
                        </div>

                        <div className="col-sm-12 col-xl-12 col-lg-12" style={{ marginTop: 10, marginBottom: 10 }}>
                            <a href="javascript:void(0);" class="btn_1 full-width mb_5" onClick={() => edit()}>{loading == 0 ? "Soumettre": <PulseLoader size={8} color="#ffffff"/>}</a>
                        </div>
                    </div>
                </div>

                <div class="col-xl-12 col-lg-12" style={{ display: addForm == true ? 'block' : 'none' }}>

                    <div className="row">
                        <div className="col-sm-12 col-xl-4 col-lg-4">
                            <div className="form-group">
                                <label>Prénom</label>
                                <input type="text" className="form-control" name="first_name" id="first_name" />
                            </div>
                        </div>
                        <div className="col-sm-12 col-xl-4 col-lg-4">
                            <div className="form-group">
                                <label>Nom de famille</label>
                                <input type="text" className="form-control" name="last_name" id="last_name" />
                            </div>
                        </div>
                        <div className="col-sm-12 col-xl-4 col-lg-4">
                            <div className="form-group">
                                <label>Numéro de portable</label>
                                <input type="text" className="form-control" name="phone" id="phone" />
                            </div>
                        </div>

                        <div className="col-sm-12 col-xl-12 col-lg-12" style={{ marginTop: 10, marginBottom: 10 }}>
                            <label>Adresse ligne 1</label>
                            <input type="text" className="form-control" name="address1" id="address1" />
                        </div>

                        <div className="col-sm-12 col-xl-12 col-lg-12" style={{ marginTop: 10, marginBottom: 10 }}>
                            <label>Adresse ligne 2</label>
                            <input type="text" className="form-control" name="address2" id="address2" />
                        </div>

                        <div className="col-sm-12 col-xl-12 col-lg-12" style={{ marginTop: 10, marginBottom: 10 }}>
                            <label>Adresse ligne 3</label>
                            <input type="text" className="form-control" name="address3" id="address3" />
                        </div>


                        <div className="col-sm-12 col-xl-4 col-lg-4">
                            <div className="form-group">
                                <label>Ville</label>
                                <input type="text" className="form-control" name="ville" id="ville" />
                            </div>
                        </div>
                        <div className="col-sm-12 col-xl-4 col-lg-4">
                            <div className="form-group">
                                <label>Point de repère</label>
                                <input type="text" className="form-control" name="land_mark" id="land_mark" />
                            </div>
                        </div>
                        <div className="col-sm-12 col-xl-4 col-lg-4">
                            <div className="form-group">
                                <label>Code Postal</label>
                                <input type="text" className="form-control" name="pincode" id="pincode" />
                            </div>
                        </div>

                        <div className="col-sm-12 col-xl-4 col-lg-4">
                            <input checked type="radio" name="type" value="home" id="maison" /> <label htmlFor="maison"> MAISON </label>
                        </div>

                        <div className="col-sm-12 col-xl-4 col-lg-4">
                            <input type="radio" name="type" value="work" id="travail" /> <label htmlFor="travail">TRAVAIL</label>
                        </div>

                        <div className="col-sm-12 col-xl-4 col-lg-4">
                            <input type="radio" name="type" value="other" id="autre" /> <label htmlFor="autre">AUTRE</label>
                        </div>

                        <div className="col-sm-12 col-xl-12 col-lg-12" style={{ marginTop: 10, marginBottom: 10 }}>
                            <a href="javascript:void(0);" class="btn_1 full-width mb_5" onClick={() => add()}>{loading == 0 ? "Soumettre": <PulseLoader size={8} color="#ffffff"/>}</a>
                        </div>
                    </div>
                </div>


                {
                    address.map((a, k) =>

                        <div className="col-sm-4">

                            <label className={k === 0 ? "card active dd" : "card dd"}>
                                <div className="address-type">{a.type}</div>
                                <div>
                                    {a.deliver_to_fname} {a.deliver_to_lname} <br />
                                    {a.address_line1} <br /> {a.address_line2} <br /> {a.address_line3}<br /> {a.landmark}<br />
                                    {a.city} {a.pincode}
                                </div>

                                <div style={{ marginTop: 15 }}>
                                    <span onClick={() => editFormShow(a)} style={{ cursor: 'pointer', fontSize: 12 }} class="fs1" aria-hidden="true" data-icon="j">{t('edit')}</span>
                                    <span onClick={() => del(a.id)} style={{ cursor: 'pointer', fontSize: 12, float: 'right'}} class="fs1" aria-hidden="true" data-icon="">Delete</span>
                                </div>
                            </label>

                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default ShippingAddress;
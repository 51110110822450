import React, { useState, useEffect, useRef, useParams } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Header from "../components/Header"
import Footer from "../components/Footer"
import '../styles/detail-page.css';
import Cookies from 'js-cookie';
import { post } from "../adapters/index";
import { useTranslation } from "react-i18next";
import "../translations/i18n";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import CartList from '../components/CartList'
import { reactLocalStorage } from 'reactjs-localstorage';
import Checkout from './Checkout';
import { openLogin, openLoginAfter } from '../actions/index';
import PulseLoader from 'react-spinners/PulseLoader';

import MyAccount from '../components/MyAccount';
import ShippingAddress from '../components/ShippingAddress';
import SupportTicket from '../components/SupportTicket';

const Dashboard = () => {
    const { t } = useTranslation();
    const [menu, setMenu] = useState(1);

    return (
        <div>
            <Header />

            <main>
                <div class="page_header element_to_stick">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-8 col-lg-7 col-md-7 d-none d-md-block">
                                <h1><span style={{ textTransform: 'capitalize' }}>{t('dashboard')}</span> </h1>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="container margin_30_40 order">
                    <div className="row">
                        <div className="col-sm-3">
                            <div className="dashboard">
                                <ul>
                                    <li onClick={() => setMenu(1)} aria-hidden="true" data-icon="8" style={{ textTransform: 'capitalize' }}><a href="javascript:void(0);">{t('my_account')}</a></li>
                                    <li aria-hidden="true" data-icon="8" style={{ textTransform: 'capitalize' }}><a href="/my_orders">{t('my_orders')}</a></li>
                                    <li onClick={() => setMenu(2)} aria-hidden="true" data-icon="8" style={{ textTransform: 'capitalize' }}><a href="javascript:void(0);">{t('my_shipping_address')}</a></li>
                                    <li aria-hidden="true" data-icon="8" style={{ display: 'none', textTransform: 'capitalize' }}>{t('my_wishlist')}</li>
                                    <li onClick={() => setMenu(3)} aria-hidden="true" data-icon="8" style={{ textTransform: 'capitalize' }}><a href="javascript:void(0);">Mes billets</a></li>
                                    <li aria-hidden="true" data-icon="8" style={{ textTransform: 'capitalize' }}><a href="/logout">{t('logout')}</a></li>


                                </ul>
                            </div>
                        </div>

                        <div className="col-sm-8 col-lg-8">
                            <div className="row">
                                <div className="col-sm-12 dashboard-details">
                                    {
                                        menu == 1
                                            ?
                                            <MyAccount />
                                            :
                                            null
                                    }

                                    {
                                        menu == 2
                                            ?
                                            <ShippingAddress />
                                            :
                                            null
                                    }

                                    {
                                        menu == 3
                                            ?
                                            <SupportTicket />
                                            :
                                            null
                                    }



                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>


            <Footer />
        </div>
    )
}

export default Dashboard;
import React, { useState, useEffect, useRef, useParams } from 'react';
import HeaderHome from "../components/HeaderHome"
import Footer from "../components/Footer"
import '../styles/submit.css';
import '../styles/contacts.css';
import faq from '../images/faq.jpg';


const Faq = () => {
    return (
        <div>
            <HeaderHome />
            <div class="hero_single inner_pages background-image" data-background={"url(" + faq + ")"}>
                <div class="opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.6)">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-xl-9 col-lg-10 col-md-8">
                                <h1>FAQ</h1>
                                <p>Toutes vos questions</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="bg_gray">
			<div class="container margin_60_40">			


			<div class="row justify-content-center">
				<div class="col-lg-8 col-md-10">
					<div class="main_title center">
						<h3 style={{marginBottom: 0}}>Questions fréquemment posées!</h3>
						<p>Jetez un œil à toutes les questions fréquemment posées</p>
					</div>

					<div role="tablist" class="add_bottom_15 accordion_2" id="accordion_group">
						<div class="card">
							<div class="card-header" role="tab">
								<h5>
									<a data-toggle="collapse" href="#collapseOne" aria-expanded="true"><i class="indicator icon_minus-06"></i>Qui sommes-nous?</a>
								</h5>
							</div>

							<div id="collapseOne" class="collapse show" role="tabpanel" data-parent="#accordion_group">
								<div class="card-body">
									<p>B.e Halal est notre nouvelle plateforme de livraison qui vous permet de commander vos viandes depuis votre boucherie préférée.</p>
								</div>
							</div>
						</div>
						<div class="card">
							<div class="card-header" role="tab">
								<h5>
									<a class="collapsed" data-toggle="collapse" href="#collapseTwo" aria-expanded="false">
										<i class="indicator icon_plus"></i>Comment obtenir un reçu ou une facture?
									</a>
								</h5>
							</div>
							<div id="collapseTwo" class="collapse" role="tabpanel" data-parent="#accordion_group">
								<div class="card-body">
									<p>Pour obtenir un reçu vous pouvez le télécharger dans votre historique de commandes dans la rubrique « mes commandes »</p>
									<p>Si toutefois vous souhaitez une facture, vous pouvez en faire la demande à notre support client à <a href="mailto:contact@behalal.fr">contact@behalal.fr</a></p>
								</div>
							</div>
						</div>
						<div class="card">
							<div class="card-header" role="tab">
								<h5>
									<a class="collapsed" data-toggle="collapse" href="#collapseThree" aria-expanded="false">
										<i class="indicator icon_plus"></i>Comment commander vos viandes et vos produits avec B.e Halal?
									</a>
								</h5>
							</div>
							<div id="collapseThree" class="collapse" role="tabpanel" data-parent="#accordion_group">
								<div class="card-body">
									<p>1. Téléchargez l’application B.e Halal et enregistrez votre adresse de livraison.</p>
									<p>2. Choisissez votre Boucherie préférée.</p>
									<p>2. Effectuez votre commande et assurez son suivi en temps réel depuis l’application.</p>
								</div>
							</div>
						</div>
						<div class="card">
							<div class="card-header" role="tab">
								<h5>
									<a class="collapsed" data-toggle="collapse" href="#collapseFive" aria-expanded="false">
										<i class="indicator icon_plus"></i>Vous souhaitez nous contacter? Vous avez une suggestion, une question sur notre service? Une erreur sur votre commande?
									</a>
								</h5>
							</div>
							<div id="collapseFive" class="collapse" role="tabpanel" data-parent="#accordion_group">
								<div class="card-body">
									<p>Vous pouvez nous contacter directement en nous écrivant à <a href="mailto:contact@behalal.fr">contact@behalal.fr</a></p>
								</div>
							</div>
						</div>
						<div class="card">
							<div class="card-header" role="tab">
								<h5>
									<a class="collapsed" data-toggle="collapse" href="#collapseSix" aria-expanded="false">
										<i class="indicator icon_plus"></i>Quels sont les délais de livraison?
									</a>
								</h5>
							</div>
							<div id="collapseSix" class="collapse" role="tabpanel" data-parent="#accordion_group">
								<div class="card-body">
									<p>Nous vous livrons selon vos disponibilités au jour J, J+1 ou J+2, depuis la boucherie jusqu’à votre domicile.</p>
								</div>
							</div>
						</div>
						<div class="card">
							<div class="card-header" role="tab">
								<h5>
									<a class="collapsed" data-toggle="collapse" href="#collapseSeven" aria-expanded="false">
										<i class="indicator icon_plus"></i>Il manque un produit dans ma commande!
									</a>
								</h5>
							</div>
							<div id="collapseSeven" class="collapse" role="tabpanel" data-parent="#accordion_group">
								<div class="card-body">
									<p>Il s’agit peut-être d’un produit indisponible et l’un des membres de l’équipe B.e Halal a déjà essayé de vous joindre afin de vous prévenir. Veuillez bien vérifier vos e-mails. Dans ce cas, votre facture est déjà ajustée. Si ce n’est pas le cas, contactez-nous par mail à <a href="mailto:contact@behalal.fr">contact@behalal.fr</a> 
</p>
								</div>
							</div>
						</div>
						<div class="card">
							<div class="card-header" role="tab">
								<h5>
									<a class="collapsed" data-toggle="collapse" href="#collapseEight" aria-expanded="false">
										<i class="indicator icon_plus"></i>Peut-on programmer une livraison pour le lendemain?
									</a>
								</h5>
							</div>
							<div id="collapseEight" class="collapse" role="tabpanel" data-parent="#accordion_group">
								<div class="card-body">
									<p>Le lendemain ou 3 jours : programmez vous-même la date de livraison. Notre coursier récupérera votre commande au moment indiqué. Nos bouchers partenaire s’engagent à préparer vos commandes le jour même de la livraison.</p>
								</div>
							</div>
						</div>
						<div class="card">
							<div class="card-header" role="tab">
								<h5>
									<a class="collapsed" data-toggle="collapse" href="#collapseNine" aria-expanded="false">
										<i class="indicator icon_plus"></i>Puis-je prendre ma commande à emporter?
									</a>
								</h5>
							</div>
							<div id="collapseNine" class="collapse" role="tabpanel" data-parent="#accordion_group">
								<div class="card-body">
									<p>Évidement ! Nous proposons la vente à emporter. Une fois que vous avez passée commande, vous recevrez une notification vous informant que votre commande est prête ! Vous n’avez plus qu’à la récupérer directement auprès de votre boucherie préférée.</p>
								</div>
							</div>
						</div>
						<div class="card">
							<div class="card-header" role="tab">
								<h5>
									<a class="collapsed" data-toggle="collapse" href="#collapseTen" aria-expanded="false">
										<i class="indicator icon_plus"></i>Qu’en est-il de la chaîne de froid ?
									</a>
								</h5>
							</div>
							<div id="collapseTen" class="collapse" role="tabpanel" data-parent="#accordion_group">
								<div class="card-body">
									<p>Évidement B.e s’occupe de tout ! Nos coursiers sont équipés de caissons isotherme et de plaques eutectiques froides pour maintenir la température de votre viande tous au long du trajet.</p>
								</div>
							</div>
						</div>
						<div class="card">
							<div class="card-header" role="tab">
								<h5>
									<a class="collapsed" data-toggle="collapse" href="#collapseEleven" aria-expanded="false">
										<i class="indicator icon_plus"></i>Que faire si ma commande est en retard?
									</a>
								</h5>
							</div>
							<div id="collapseEleven" class="collapse" role="tabpanel" data-parent="#accordion_group">
								<div class="card-body">
									<p>Il peut y avoir des retards qui sont malheureusement indépendant de notre volonté. Le numéro B.e Halal est disponible afin qu’on puisse rapidement remédier à ce type d’imprévu. L’ensemble des membres de l’équipe fera son maximum pour vous livrer aussi rapidement que possible.
</p>
								</div>
							</div>
						</div>
						<div class="card">
							<div class="card-header" role="tab">
								<h5>
									<a class="collapsed" data-toggle="collapse" href="#collapseTwelve" aria-expanded="false">
										<i class="indicator icon_plus"></i>Si je suis absent au moment de réceptionner ma commande ? que se passera t-il? 
									</a>
								</h5>
							</div>
							<div id="collapseTwelve" class="collapse" role="tabpanel" data-parent="#accordion_group">
								<div class="card-body">
									<p>Notre coursier déposera votre commande chez votre boucher. Vous pourrez la récupérer à tout moment.
</p>
								</div>
							</div>
						</div>
						<div class="card">
							<div class="card-header" role="tab">
								<h5>
									<a class="collapsed" data-toggle="collapse" href="#collapseThirteen" aria-expanded="false">
										<i class="indicator icon_plus"></i>Je ne trouve pas ma boucherie habituelle, comment faire?
									</a>
								</h5>
							</div>
							<div id="collapseThirteen" class="collapse" role="tabpanel" data-parent="#accordion_group">
								<div class="card-body">
									<p>Contactez-nous vite en nous écrivant à <a href="mailto:contact@behalal.fr">contact@behalal.fr</a>. Un membre B.e Halal s’occupera de contacter et d’enregistrer votre boucherie dans les plus brefs délais 

</p>
								</div>
							</div>
						</div>
						<div class="card">
							<div class="card-header" role="tab">
								<h5>
									<a class="collapsed" data-toggle="collapse" href="#collapseFourteen" aria-expanded="false">
										<i class="indicator icon_plus"></i>Un produit n’est pas conforme ou est abîmé, que faire? Vous avez réceptionné une viande  de mauvaise qualité? Votre boucherie  s’est trompé dans la quantité qu’il vous a envoyé ? Il s’est trompé de produit?

									</a>
								</h5>
							</div>
							<div id="collapseFourteen" class="collapse" role="tabpanel" data-parent="#accordion_group">
								<div class="card-body">
									<p>Veuillez nous envoyer une photo du produit concerné ainsi que votre nom et le numéro de votre commande par mail à <a href="mailto:contact@behalal.fr">contact@behalal.fr</a>, l’équipe B.e Halal fera son maximum pour traiter votre plainte, améliorer la qualité du service et vous dédommager si besoin.
</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			</div>
		</div>

            <Footer />
        </div>
    )
}

export default Faq;
import React, { useState, useEffect, useRef, useParams } from 'react';
import '../styles/detail-page.css';
import Cookies from 'js-cookie';
import { post, get } from "../adapters/index";
import { useTranslation } from "react-i18next";
import "../translations/i18n";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import PulseLoader from 'react-spinners/PulseLoader';
import { store } from 'react-notifications-component';

const MyAccount = () => {
    const { t } = useTranslation();
    const [myAccount, setMyAccount] = useState([]);
    const [error, setError] = useState(0);
    const [loading, setLoading] = useState(0);

    useEffect(function () {
        _loadAccount();
    }, []);

    const _loadAccount = () => {
        const form = new FormData();
        form.append('butcher_id', Cookies.get('bhId'));
            

        get('customer/myaccount', form).then(async function (response) {
            setMyAccount(response.data.data);
        }).catch(function (error) {});
    }

    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    const save = () => {
        setError(0);
        var first_name = document.getElementById('first_name');
        var last_name = document.getElementById('last_name');
        var email = document.getElementById('email');

        if (first_name.value.length == 0) {
            setError(1);
            return false;
        }
        else if (last_name.value.length == 0) {
            setError(2);
            return false;
        }
        else if (email.value.length == 0) {
            setError(3);
            return false;
        }

        if (!validateEmail(email.value)) {
            setError(3);
            return false;
        }

        setLoading(1);

        const form = new FormData();
		form.append('butcher_id', Cookies.get('bhId'));
		form.append('fname', first_name.value);
        form.append('lname', last_name.value);
        form.append('email', email.value);

		post('customer/update', form).then(async function (response) {
			setLoading(0);
            store.addNotification({
                title: "Succès",
                message: "Enregistré avec succès",
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
		}).catch(function (error) {
            setLoading(0);
            setError(4);
            return false;
		});
    }

    return (
        <>
            <h5 style={{ textTransform: 'capitalize' }}>
                {t('my_account')}
            </h5><hr/>

            <div className="row">
                <div className="col-sm-6">
                    <div className="form-group">
                        <label>{t('first_name')}</label>
                        <input defaultValue={myAccount.fname} type="text" className="form-control" name="first_name" id="first_name" />
                        {error == 1 ? <div className="error">Entrez s'il vous plait prénom</div> : null}
                    </div>
                </div>

                <div className="col-sm-6">
                    <div className="form-group">
                        <label>{t('last_name')}</label>
                        <input defaultValue={myAccount.lname} type="text" className="form-control" name="last_name" id="last_name" />
                        {error == 2 ? <div className="error">Entrez s'il vous plait nom de famille</div> : null}
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-6">
                    <div className="form-group">
                        <label>{t('mobile_number')}</label>
                        <input defaultValue={myAccount.phone} type="text" className="form-control" name="mobile" id="mobile" readOnly/>
                    </div>
                </div>

                <div className="col-sm-6">
                    <div className="form-group">
                        <label>{t('email')}</label>
                        <input defaultValue={myAccount.email} type="text" className="form-control" name="email" id="email" />
                        {error == 3 ? <div className="error">Entrez s'il vous plait e-mail</div> : null}
                        {error == 4 ? <div className="error">l'adresse mail existe déjà</div> : null}
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12" style={{marginTop:20}}>
                    <a href="javascript:void(0);" onClick={() => save()} class="btn_1 full-width mb_5">
                        {loading == 0 ? t('save'): <PulseLoader size={8} color="#ffffff"/>}
                    </a>
                </div>
            </div>

        </>
    )
}

export default MyAccount;
import React, { useState, useEffect, useRef, useParams } from 'react';
import HeaderHome from "../components/HeaderHome"
import Footer from "../components/Footer"
import '../styles/submit.css';
import partner from '../images/partner.jpg';
import partner1 from '../images/partner1.svg';
import partner2 from '../images/partner2.svg';
import avatar_placeholder from '../images/avatar-placeholder.jpg';
import banner_partner from '../images/banner_partner.jpg';

const Partner = () => {


    return (
        <div>
            <HeaderHome />
            <div class="hero_single inner_pages background-image" data-background={"url(" + partner + ")"}>
                <div class="opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.6)">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-xl-9 col-lg-10 col-md-8">
                                <h1>Devenir Boucherie partenaire</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container margin_30_40">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="singlepost">

                            <h1>Les avantages B.e Halal</h1>

                            <div class="post-content">

                                <p><i class="icon_circle-slelected"></i> Une application très simple d’utilisation, qui facilite les achats de vos produits </p>
                                <p><i class="icon_circle-slelected"></i> Vous proposez un produit digital, très demandé par les consommateurs </p>
                                <p><i class="icon_circle-slelected"></i> Vous soutenez une solution 100% musulmane, avec une application et une entreprise Made in France!</p>
                                <p><i class="icon_circle-slelected"></i> Un support français intégralement géré par l’entreprise B.e Halal</p>
                                <p><i class="icon_circle-slelected"></i> Vous bénéficiez d’un plan de communication massif (publicité web, TV et presse)</p>
                                <p><i class="icon_circle-slelected"></i> Faites plus d’affaires, avec moins d’effort sans que vous ayez besoin de lever le petit doigt! </p>
                                <p><i class="icon_circle-slelected"></i> Atteignez de nouveaux clients, améliorez vos marges bénéficiaires et augmentez la présence de votre marque </p>
                                <p><i class="icon_circle-slelected"></i> Consommez responsable 2.5 % des bénéfices de B.e Halal seront reversez directement à des œuvres caritatives comme le secours islamiques et la fondation l’Abbé Pierre.</p>
                                <p><b>PS</b> : Attention, nous avons actuellement une forte demande.<br />
                                    Nous vous invitons à prendre rapidement contact avec nous via notre site ou notre application </p>



                            </div>
                        </div>
                        <hr />
                    </div>
                </div>
            </div>

            <div>
                <div class="text-center add_bottom_15">
                    <h2>Comment fonctionne B.e Halal pour les boucheries partenaires</h2>
                </div>

                <div class="owl-carousel owl-theme carousel_1 testimonials add_top_30">
                    <div class="item">
                        <blockquote>Les clients passent commande</blockquote>
                        <p>
                            <h5>Le client trouve votre établissement et passe commande sur l'application Be.Halal</h5>
                        </p>
                        <figure><img src={avatar_placeholder} data-src={partner1} alt="" class="lazy" /></figure>

                    </div>
                    <div class="item">
                        <blockquote>Vous préparez la commande</blockquote>
                        <p>
                            <h5>Votre établissement accepte et prépare la commande</h5>
                        </p>
                        <figure><img src={avatar_placeholder} data-src={partner2} alt="" class="lazy" /></figure>
                    </div>
                </div>
            </div>

            <div class="container margin_60_40">

            <div class="banner lazy" data-bg={"url("+banner_partner+")"}>
                <div class="wrapper d-flex align-items-center opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.2)">
                    <div>
                        <small>B.e Halal</small>
                        <h3>Devenir Boucher Partenaire</h3>
                        <p>B.e Halal vous donne l'opportunité de digitaliser votre boucherie en quelques clics</p>
                        <a href="/about#form" class="btn_1">S'inscrire maintenant</a>
                    </div>
                </div>
            </div>
        </div>

            <Footer />
        </div>
    )
}

export default Partner;
import React, { useState, useEffect, useRef, useParams } from 'react';
import '../styles/detail-page.css';
import Cookies from 'js-cookie';
import { post, get } from "../adapters/index";
import { useTranslation } from "react-i18next";
import "../translations/i18n";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import PulseLoader from 'react-spinners/PulseLoader';
import { store } from 'react-notifications-component';
import '../styles/css_skelton.css';
import addCart from '../utility/Cart';

const SupportTicket = () => {
    const { t } = useTranslation();
    const [tickets, setTickets] = useState([]);
    const [status, setStatus] = useState('all');
    const [loading, setLoading] = useState(0);

    useEffect(function () {
        _loadTickets();
    }, []);

    const _loadTickets = () => {
        setLoading(true)
        get('/support/' + status).then(async function (response) {
            setTickets(response.data.data);
            setLoading(false)
        }).catch(function (error) {
        });
    }

    const ticketFilter = (e, type) => {
        setLoading(true)
        get('/support/' + type).then(async function (response) {
            setTickets(response.data.data);
            setLoading(false)
        }).catch(function (error) {
        });
    }

    return (
        <div>
            <h5>{t('my_tickets')}</h5> <hr />

            <div>
                <ul className="tickets">
                    <li>
                        <input defaultChecked={true} type="radio" name="filter" id="all" value="all" onChange={(e) => ticketFilter(e, 'all')}/>
                        <label htmlFor="all"> &nbsp;All</label>
                    </li>

                    <li>
                        <input type="radio" name="filter" id="open" value="open" onChange={(e) => ticketFilter(e.type, 'open')}/>
                        <label htmlFor="open"> &nbsp;Ouvert</label>
                    </li>

                    <li>
                        <input type="radio" name="filter" id="on_hold" value="on_hold" onChange={(e) => ticketFilter(e, 'onhold')}/>
                        <label htmlFor="on_hold"> &nbsp;En attente</label>
                    </li>

                    <li>
                        <input type="radio" name="filter" id="closed" value="closed" onChange={(e) => ticketFilter(e, 'closed')}/>
                        <label htmlFor="closed"> &nbsp;Fermé</label>
                    </li>
                </ul>

                <br/>

                {
                    loading === false
                    ?
                        <table className="ticket_table">
                            <thead>
                                <tr>
                                    <th>ID de billets</th>
                                    <th>Matière</th>
                                    <th>Statut</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tickets.map((ti, k) => 
                                    <>
                                        <tr>
                                            <td className="bottom0">{ti.id}</td>
                                            <td className="bottom0">{ti.subject}</td>
                                            <td className="bottom0">{t(ti.status)}</td>
                                            <td className="bottom0">{ti.created_at}</td>
                                        </tr>
                                        <tr>
                                            <td style={{border:0}}>
                                                &nbsp;
                                            </td>
                                            <td colSpan="3" className="top0">
                                                <div style={{paddingBottom: 10}}><span className="reply">Réponse de l'assistance - {ti.updated_at}</span> </div>
                                                {ti.response}
                                            </td>
                                        </tr>
                                    </>
                                )}
                                {
                                    tickets.length == 0
                                    ?
                                        <tr>
                                            <td colSpan="4" style={{textAlign: 'center'}}>
                                            Il n'y a pas de billets
                                            </td>
                                        </tr>
                                    :
                                    null
                                }
                            </tbody>
                        </table>
                    :
                    <div style={{clear: 'both', textAlign: 'center',marginTop: 20}}>
                        <PulseLoader size={8} color="#83c55e"/>
                    </div>
                }
                
            </div>
        </div>
    )
}

export default SupportTicket;
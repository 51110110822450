import React, { useState, useEffect, useRef, useParams } from 'react';
import HeaderHome from "../components/HeaderHome"
import Footer from "../components/Footer"
import '../styles/submit.css';
import '../styles/contacts.css';
import terms from '../images/terms.jpg';


const Cgv = () => {
    return (
        <div>
            <HeaderHome />
            <div class="hero_single inner_pages background-image" data-background={"url(" + terms + ")"}>
                <div class="opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.6)">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-xl-9 col-lg-10 col-md-8">
                                <h1>Conditions Générales de Vente</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container margin_30_40">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="singlepost2">

                            <h1>Conditions Générales de Vente</h1>

                            <div class="post-content">

                                <p>Les pr&eacute;sentes conditions g&eacute;n&eacute;rales de vente (&laquo; <strong>CGV</strong> &raquo;) sont propos&eacute;es par la soci&eacute;t&eacute; B.e Halal, soci&eacute;t&eacute; par actions simplifi&eacute;e au capital de 10000 &euro;, dont le si&egrave;ge social est situ&eacute; au 11 rue des Pyr&eacute;n&eacute;es 91090 Lisses, enregistr&eacute;e au Registre du Commerce et des Soci&eacute;t&eacute;s d&rsquo;Evry sous le num&eacute;roro 897 857 660</p>
                                <p>Les CGV ont pour objet de r&eacute;gir les conditions d&rsquo;utilisation du site accessible &agrave; l&rsquo;adresse <a href="http://www.behalal.fr">www.behalal.fr</a> par les commer&ccedil;ants et clients inscrits sur le Site tels que d&eacute;finis ci- apr&egrave;s, ainsi que par les visiteurs du Site.</p>
                                <p>L&rsquo;application est h&eacute;berg&eacute;e par B.e Halal</p>
                                <p>Le directeur de la publication est Monsieur El Kasmi Sa&iuml;d.</p>
                                <p>&nbsp;</p>
                                <p><strong><u>ARTICLE 1 : D&Eacute;</u></strong><strong><u>FINITIONS</u></strong></p>
                                <p><strong>Client</strong> d&eacute;signe toute personne particuli&egrave;re ou tout professionnel souhaitant faire l&rsquo;acquisition d&rsquo;un produit propos&eacute; &agrave; la vente par un Commer&ccedil;ant sur la Plateforme.</p>
                                <p><strong>Annonce</strong> d&eacute;signe toute offre de vente d&rsquo;un ou plusieurs Produit(s) publi&eacute;e par un Commer&ccedil;ant.</p>
                                <p><strong>Compte</strong> d&eacute;signe l&rsquo;espace personnel de chaque Utilisateur sur la Plateforme.</p>
                                <p><strong>Plateforme</strong> d&eacute;signe l&rsquo;outil mis &agrave; disposition par la Soci&eacute;t&eacute; sur le Site et permettant la mise en relation des Commer&ccedil;ants et des Clients.</p>
                                <p><strong>Produit</strong> d&eacute;signe le produit propos&eacute; &agrave; la vente par un Commer&ccedil;ant ou recherch&eacute; par un Client sur la Plateforme.</p>
                                <p><strong>Site</strong> d&eacute;signe le site Internet accessible &agrave; l&rsquo;adresse www.behalal.fr</p>
                                <p><strong>Soci&eacute;t&eacute;</strong> d&eacute;signe la soci&eacute;t&eacute; B.e Halal, soci&eacute;t&eacute; par actions simplifi&eacute;e au capital de 10000 &euro;, dont le si&egrave;ge social est situ&eacute; au 11 rue des Pyr&eacute;n&eacute;es 91090 &agrave; Lisses, enregistr&eacute;e au Registre du Commerce et des Soci&eacute;t&eacute;s d&rsquo;Evry sous le num&eacute;ro 897 857 660.</p>
                                <p><strong>Utilisateur</strong> d&eacute;signe &agrave; la fois un Visiteur, un Commer&ccedil;ant, un Client pris individuellement ou un Visiteur, un Commer&ccedil;ant et un Client pris ensemble.</p>
                                <p><strong>Commer&ccedil;ant</strong> d&eacute;signe toute personne agissant &agrave; titre professionnel et proposant &agrave; la vente un Produit sur la Plateforme.</p>
                                <p><strong>Visiteur</strong> d&eacute;signe toute personne acc&eacute;dant au Site.</p>
                                <p>&nbsp;</p>
                                <p><strong><u>ARTICLE 2 : OBJET</u></strong></p>
                                <p>La Soci&eacute;t&eacute; propose sur le Site une Plateforme de mise en relation entre particuliers et professionnels leur permettant la recherche et/ou la vente de produits de boucherie par l&rsquo;interm&eacute;diaire de la plateforme (la &laquo; Plateforme &raquo;).</p>
                                <p>Les CGV r&eacute;gissent les conditions d&rsquo;utilisation de la Plateforme et les r&egrave;gles applicables &agrave; la relation entre d&rsquo;une part la Soci&eacute;t&eacute; et les Utilisateurs, et d&rsquo;autre part entre les Clients et les Commer&ccedil;ants.</p>
                                <p>En outre, s&rsquo;agissant des Commer&ccedil;ants professionnels, les Clients sont inform&eacute;s que les conditions g&eacute;n&eacute;rales de vente des Commer&ccedil;ants professionnels, le cas &eacute;ch&eacute;ant, s&rsquo;appliquent en sus des pr&eacute;sentes CGV.</p>
                                <p>Les pr&eacute;sentes CGV remplacent et annulent tout accord ant&eacute;rieur, &eacute;crit ou oral, entre d&rsquo;une part la Soci&eacute;t&eacute; et les Utilisateurs, et d&rsquo;autre part entre les Clients et les Commer&ccedil;ants, et contient l&rsquo;entier accord entre eux.</p>
                                <p>Le fait pour tout Utilisateur de visiter le Site constitue une acceptation irr&eacute;vocable et sans r&eacute;serve de l&rsquo;int&eacute;gralit&eacute; des stipulations des pr&eacute;sentes.</p>
                                <p>&nbsp;</p>
                                <p><strong><u>ARTICLE 3: DISPONIBILIT</u></strong><strong><u>&Eacute; </u></strong><strong><u>DE LA PLATEFORME</u></strong></p>
                                <p>La Plateforme est accessible 24 heures sur 24 et 7 jours sur 7.</p>
                                <p>Toutefois, la Soci&eacute;t&eacute; ne fournit aucune garantie concernant cette disponibilit&eacute;.</p>
                                <p>Ainsi, les Utilisateurs sont inform&eacute;s et reconnaissent que l&rsquo;acc&egrave;s &agrave; la Plateforme pourra &ecirc;tre suspendu ou alt&eacute;r&eacute; notamment pour des op&eacute;rations de maintenance et ce, sans indemnisation possible des Utilisateurs.</p>
                                <p>&nbsp;</p>
                                <p><strong><u>ARTICLE 4 : CONDITIONS D</u></strong><strong><u>&rsquo;</u></strong><strong><u>INSCRIPTION SUR LE SITE ET LA PLATEFORME</u></strong></p>
                                <p>Seuls les Utilisateurs &acirc;g&eacute;s de plus de 18 ans sont autoris&eacute;s &agrave; s&rsquo;inscrire sur la Plateforme.</p>
                                <p>L&rsquo;Utilisateur qui souhaite b&eacute;n&eacute;ficier des services offerts par la Plateforme est invit&eacute; &agrave; y cr&eacute;er un Compte en se connectant au Site et en suivant les instructions qui lui sont indiqu&eacute;es &agrave; cet &eacute;gard :</p>
                                <p><strong>4.1 Cr&eacute;ation d</strong><strong>&rsquo;</strong><strong>un compte</strong></p>
                                <p>Plus particuli&egrave;rement, l&rsquo;Utilisateur doit compl&eacute;ter et renseigner un certain nombre d&rsquo;informations le concernant et notamment sans que cela soit limitatif :</p>
                                <p>&ndash; Identit&eacute; (nom, pr&eacute;nom ou raison sociale le cas &eacute;ch&eacute;ant),</p>
                                <p>&ndash; Adresse postale,</p>
                                <p>&ndash; Adresse &eacute;lectronique,</p>
                                <p>&ndash; T&eacute;l&eacute;phone,</p>
                                <p>&ndash; Identifiant,</p>
                                <p>&ndash; Mot de passe.</p>
                                <p>Il est en outre pr&eacute;cis&eacute; que la Soci&eacute;t&eacute; se r&eacute;serve le droit de demander des documents ou &eacute;l&eacute;ments suppl&eacute;mentaires aux Utilisateurs en tant que de besoin et &agrave; sa libre discr&eacute;tion tels que, sans que cela soit limitatif, des justificatifs d&rsquo;identit&eacute; ou extrait Kbis concernant les Commer&ccedil;ants.</p>
                                <p>Une fois ces informations renseign&eacute;es, l&rsquo;Utilisateur doit valider l&rsquo;adresse &eacute;lectronique indiqu&eacute;e lors de son inscription en cliquant sur un lien qu&rsquo;&rsquo;il aura re&ccedil;u &agrave; cette adresse. Il re&ccedil;oit alors sur cette adresse un mail lui confirmant son inscription &agrave; la Plateforme.</p>
                                <p>D&egrave;s confirmation, l&rsquo;utilisateur peut acc&eacute;der &agrave; la Plateforme et &agrave; son Compte en se connectant &agrave; l&rsquo;aide de son identifiant et de son mot de passe.</p>
                                <p><strong>4.2 Connexion via un service tiers</strong></p>
                                <p>L&rsquo;Utilisateur peut &eacute;galement se connecter &agrave; son Compte via un service tiers, notamment en utilisant la fonction &laquo; Facebook Connect &raquo; ou &laquo; Google Account &raquo;. En activant cette fonction, l&rsquo;Utilisateur permet &agrave; la Soci&eacute;t&eacute; d&rsquo;acc&eacute;der et d&rsquo;utiliser aux fins des pr&eacute;sentes les informations de son compte Facebook ou Google qu&rsquo;il aura rendues publiques.</p>
                                <p>Une fois son inscription au Site valid&eacute;e, l&rsquo;Utilisateur peut acheter ou vendre des Produits. L&rsquo;Utilisateur prenant alors alternativement le statut de Commer&ccedil;ant et/ou celui d&rsquo;Client.</p>
                                <p>&nbsp;</p>
                                <p><strong><u>ARTICLE 5 : CONDITIONS DE R&Eacute;F&Eacute;</u></strong><strong><u>RENCEMENT DES PRODUITS SUR LA PLATEFORME</u></strong></p>
                                <p><strong>5.1 Nature des Produits</strong></p>
                                <p>Cela inclut notamment, sans que cela soit limitatif, les produits que peuvent proposer de fa&ccedil;on r&eacute;guli&egrave;re des bouchers.</p>
                                <p>La Soci&eacute;t&eacute; se r&eacute;serve le droit de refuser la vente d&rsquo;un produit sur la Plateforme ou la publication d&rsquo;une Annonce &agrave; tout moment sans avoir &agrave; le justifier.</p>
                                <p>L&rsquo;Utilisateur est alors averti par l&rsquo;envoi d&rsquo;un message &eacute;lectronique.</p>
                                <p><strong>5.2 Description des Produits</strong></p>
                                <p>Dans le cadre du r&eacute;f&eacute;rencement d&rsquo;un Produit sur la Plateforme, le Commer&ccedil;ant doit remplir un formulaire descriptif dans lequel il doit indiquer les caract&eacute;ristiques du Produit vendu et notamment sans que cela soit limitatif :</p>
                                <p>&ndash; Photographies du bien,</p>
                                <p>&ndash; Caract&eacute;ristiques du bien,</p>
                                <p>&ndash; Origine du bien,</p>
                                <p>&ndash; Prix de vente propos&eacute; par le Commer&ccedil;ant.</p>
                                <p>Ce formulaire descriptif est amen&eacute; &agrave; &eacute;voluer au fil du temps, ce que les Commer&ccedil;ants reconnaissent et acceptent. Ainsi, au cas par cas, la Soci&eacute;t&eacute; peut &ecirc;tre amen&eacute;e &agrave; demander des &eacute;l&eacute;ments suppl&eacute;mentaires au Commer&ccedil;ant.</p>
                                <p>Les informations renseign&eacute;es dans le formulaire descriptif sont utilis&eacute;es par la Soci&eacute;t&eacute; pour permettre la publication de l&rsquo;annonce sur la Plateforme.</p>
                                <p>L&rsquo;Utilisateur est responsable de la r&eacute;daction de l&rsquo;Annonce aff&eacute;rente au Produit. Il s&rsquo;oblige notamment &agrave; n&rsquo;y faire figurer que des informations exactes, sinc&egrave;res et l&eacute;gales, etc., excluant notamment tout contenu (texte ou image) &agrave; caract&egrave;re raciste, obsc&egrave;ne, pornographique, etc.</p>
                                <p>&nbsp;</p>
                                <p><strong><u>ARTICLE 6 : PRIX DES PRODUITS</u></strong></p>
                                <p>Le prix des Produits vendus sur la Plateforme par les Commer&ccedil;ants est fix&eacute; librement par les Commer&ccedil;ants sans intervention directe de la Soci&eacute;t&eacute; dans la d&eacute;termination de ce prix.</p>
                                <p>En outre, ce prix pourra &ecirc;tre modifi&eacute; &agrave; tout moment par le Commer&ccedil;ant en se connectant sur son Compte.</p>
                                <p>Le prix affich&eacute; sur les Annonces est exprim&eacute; TTC.</p>
                                <p>Il ne tient pas compte des frais de livraison du Produit, qui sont pr&eacute;vus par le Commer&ccedil;ant en fonction des options choisies.</p>
                                <p>&nbsp;</p>
                                <p><strong><u>ARTICLE 7: COMMISSION</u></strong></p>
                                <p>L&rsquo;acc&egrave;s et l&rsquo;utilisation de la Plateforme sont gratuits.</p>
                                <p>La Soci&eacute;t&eacute; se r&eacute;mun&egrave;re toutefois en pr&eacute;levant une commission sur chacune des ventes effectu&eacute;es sur la Plateforme.</p>
                                <p>Le montant de cette commission est &eacute;gal &agrave; A DEFINIR du prix de vente du Produit d&eacute;termin&eacute; par le Commer&ccedil;ant.</p>
                                <p>La TVA applicable est ajout&eacute;e au montant de cette commission.</p>
                                <p>&nbsp;</p>
                                <p><strong><u>ARTICLE 8 : PAIEMENT DES PRODUITS</u></strong></p>
                                <p>Outre les services de r&eacute;f&eacute;rencement et de publication d&rsquo;annonces, la Soci&eacute;t&eacute; propose un service de paiement int&eacute;gr&eacute; &agrave; la Plateforme par lequel les Clients paient les Commer&ccedil;ants directement par l&rsquo;interm&eacute;diaire de la Plateforme.</p>
                                <p>Pour ce faire, la Soci&eacute;t&eacute; utilise les services de la soci&eacute;t&eacute; B.e Halal.</p>
                                <p>&Agrave; cet &eacute;gard, il est pr&eacute;cis&eacute; que les conditions g&eacute;n&eacute;rales de vente et d&rsquo;utilisation de B.e Halal s&rsquo;appliquent en sus des CGV, ce que les Utilisateurs reconnaissent et acceptent express&eacute;ment.</p>
                                <p>Ainsi, les Clients qui souhaitent faire l&rsquo;acquisition d&rsquo;un Produit propos&eacute; par un Commer&ccedil;ant sur la Plateforme sont dirig&eacute;s vers un serveur de paiement d&eacute;di&eacute; mis en place par la soci&eacute;t&eacute; B.e Halal.</p>
                                <p>Les Clients proc&egrave;dent ainsi au paiement du prix indiqu&eacute; par le Commer&ccedil;ant dans son annonce, et les &eacute;ventuels frais de livraison. Par cet acte d&rsquo;achat, l&rsquo;Client accepte que les informations n&eacute;cessaires &agrave; la finalisation de la transaction soient communiqu&eacute;es au Commer&ccedil;ant.</p>
                                <p><u>&nbsp;</u></p>
                                <p><strong><u>ARTICLE 9 : EXP&Eacute;</u></strong><strong><u>DITION ET LIVRAISON DES PRODUITS</u></strong></p>
                                <p>La Soci&eacute;t&eacute; ne proc&egrave;de pas directement &agrave; l&rsquo;exp&eacute;dition des Produits qui s&rsquo;ex&eacute;cute sous la seule responsabilit&eacute; des Commer&ccedil;ants qui remettent les Produits destin&eacute;s &agrave; la livraison des Clients &agrave; des coursiers ind&eacute;pendants.</p>
                                <p>Le Commer&ccedil;ant prend soin d&rsquo;assurer la livraison en bonne et due forme, en proc&eacute;dant notamment &agrave; un emballage soigneux du Produit et d&rsquo;en assurer la tra&ccedil;abilit&eacute; en conservant et partageant sur son Compte les &eacute;l&eacute;ments transmis par l&rsquo;op&eacute;rateur de livraison (num&eacute;ro de livraison, date d&rsquo;exp&eacute;dition, date de livraison pr&eacute;vue, etc.).</p>
                                <p>D&egrave;s confirmation du paiement du Produit par l&rsquo;Client, le Commer&ccedil;ant s&rsquo;engage &agrave; exp&eacute;dier dans un d&eacute;lai de 72 heures le Produit &agrave; l&rsquo;Client en utilisant le mode d&rsquo;exp&eacute;dition convenu.</p>
                                <p>&Agrave; r&eacute;ception du Produit par l&rsquo;Client, ce dernier s&rsquo;engage &agrave; confirmer sa bonne livraison et sa conformit&eacute; sur son Compte en ligne en renseignant la r&eacute;f&eacute;rence de transaction indiqu&eacute;e dans le bon de livraison.</p>
                                <p>D&egrave;s confirmation de cette r&eacute;ception et dans un d&eacute;lai de 15 jours maximum, le prix de la vente du Produit, dont la commission aura &eacute;t&eacute; d&eacute;duite pr&eacute;alablement, ainsi que les &eacute;ventuels frais de livraison avanc&eacute;s seront disponibles pour le Commer&ccedil;ant sur son compte bancaire personnel (dont les coordonn&eacute;es lui sont demand&eacute;es au moment de la confirmation de l&rsquo;achat).</p>
                                <p>Toutefois la Soci&eacute;t&eacute; se r&eacute;serve le droit de suspendre ou de modifier le versement des sommes acquitt&eacute;es en cas de r&eacute;clamation d&rsquo;un Client.</p>
                                <p>&nbsp;</p>
                                <p><strong>ARTICLE 10 : RESPONSABILIT</strong><strong>&Eacute; </strong><strong>DE LA SOCI</strong><strong>&Eacute;T&Eacute;</strong></p>
                                <p>La Soci&eacute;t&eacute; n&rsquo;agit qu&rsquo;en tant qu&rsquo;interm&eacute;diaire de mise en relation entre les Clients et les Commer&ccedil;ants.</p>
                                <p>Par ailleurs, la Soci&eacute;t&eacute; ne proc&egrave;de &agrave; aucun contr&ocirc;le des Produits vendus par le biais de la Plateforme.</p>
                                <p>La Soci&eacute;t&eacute; est d&eacute;gag&eacute;e de toute responsabilit&eacute; vis-&agrave;-vis de toute r&eacute;clamation li&eacute;e &agrave; des articles recherch&eacute;s ou vendus sur la Plateforme. &Agrave; cet &eacute;gard, la Soci&eacute;t&eacute; ne saurait &ecirc;tre tenue responsable concernant toute vente de Produits par l&rsquo;interm&eacute;diaire de la Plateforme. Notamment, la Soci&eacute;t&eacute; n&rsquo;est pas responsable de tout dommage caus&eacute; par les Produits.</p>
                                <p>En outre, la Soci&eacute;t&eacute; doit &ecirc;tre consid&eacute;r&eacute;e comme un h&eacute;bergeur au sens de la loi pour la confiance dans l&rsquo;&eacute;economie num&eacute;rique n&deg; 2004-575 du 21 juin 2004. &Agrave; ce titre, la Soci&eacute;t&eacute; b&eacute;n&eacute;ficie d&rsquo;un r&eacute;gime de responsabilit&eacute; limit&eacute;.</p>
                                <p>Par ailleurs, en ce qui concerne la mise &agrave; disposition de la Plateforme, il est entendu que la Soci&eacute;t&eacute; fournit la Plateforme en l&rsquo;&eacute;tat et ne fournit aucune garantie quant &agrave; son acc&egrave;s et &agrave; sa disponibilit&eacute;.</p>
                                <p><strong>ARTICLE 11: LIMITATION DE LA RESPONSABILIT</strong><strong>&Eacute; </strong><strong>DE LA SOCI</strong><strong>&Eacute;T&Eacute;</strong></p>
                                <p>La Soci&eacute;t&eacute; s&rsquo;engage &agrave; mettre en &oelig;uvre tous les moyens n&eacute;cessaires &agrave; sa disposition afin d&rsquo;assurer la fourniture et la mise &agrave; disposition de la Plateforme aux Utilisateurs.</p>
                                <p>Toutefois, la Soci&eacute;t&eacute; ne saurait &ecirc;tre tenue responsable dans les cas suivants :</p>
                                <p>&ndash; interruptions, pannes, modifications et dysfonctionnements de la Plateforme ;</p>
                                <p>&ndash; perte de donn&eacute;es ou d&rsquo;informations ;</p>
                                <p>&ndash; impossibilit&eacute; d&rsquo;acc&eacute;der &agrave; la Plateforme ;</p>
                                <p>&ndash; dommages directs ou indirects caus&eacute;s aux Utilisateurs, quelle qu&rsquo;en soit la raison, r&eacute;sultant des Produits et/ou de l&rsquo;acc&egrave;s et de l&rsquo;utilisation de la Plateforme.</p>
                                <p>&ndash; &eacute;ventuelles d&eacute;gradations de mat&eacute;riel, logiciel, donn&eacute;es subies par l&rsquo;Utilisateur du fait de son utilisation du Site, notamment dans le cas d&rsquo;une attaque virale ou de la propagation d&rsquo;un logiciel malveillant.</p>
                                <p>La responsabilit&eacute; de la Soci&eacute;t&eacute; ne pourra &ecirc;tre engag&eacute;e que pour les dommages directs subis par un Utilisateur, r&eacute;sultant uniquement d&rsquo;un manquement &agrave; ses obligations contractuelles telles que d&eacute;finies aux pr&eacute;sentes CGV.</p>
                                <p>Les Utilisateurs renoncent par avance &agrave; toute demande de r&eacute;paration &agrave; quelque titre que ce soit, des dommages indirects tels que le manque &agrave; gagner, la perte de chance, le pr&eacute;judice commercial ou financier r&eacute;sultant de l&rsquo;utilisation de la Plateforme.</p>
                                <p>Tout Utilisateur est seul responsable des dommages caus&eacute;s aux tiers et des cons&eacute;quences des r&eacute;clamations ou actions qui pourraient en d&eacute;couler.</p>
                                <p>&nbsp;</p>
                                <p><strong>ARTICLE 12: RESPONSABILIT</strong><strong>&Eacute; </strong><strong>ET GARANTIE DES COMMER&Ccedil;ANTS</strong></p>
                                <p>Les Commer&ccedil;ants garantissent, lors du r&eacute;f&eacute;rencement d&rsquo;un Produit et de sa vente par le biais de la Plateforme, qu&rsquo;ils disposent de tous les droits sur ce Produit et qu&rsquo;ils en sont les propri&eacute;taires. Ils s&rsquo;engagent &agrave; ne pas proposer de contrefa&ccedil;ons.</p>
                                <p>Les Commer&ccedil;ants garantissent &eacute;galement qu&rsquo;ils ont fait l&rsquo;acquisition initiale du Produit de mani&egrave;re l&eacute;gale et que sa vente sur la Plateforme n&rsquo;est pas prohib&eacute;e.</p>
                                <p>Les Commer&ccedil;ants garantissent la Soci&eacute;t&eacute; et les Clients contre toute action de tiers ou r&eacute;clamation concernant la vente des Produits sur la Plateforme.</p>
                                <p>L&rsquo;Utilisateur garantit la Soci&eacute;t&eacute; que les &eacute;l&eacute;ments enregistr&eacute;s par lui dans son Compte, notamment et de fa&ccedil;on non limitative, son pseudonyme, adresse &eacute;lectronique, etc. ne sont pas susceptibles de porter atteinte aux droits d&rsquo;un tiers (marque, d&eacute;nomination sociale, etc.) ou ne comportent pas d&rsquo;&eacute;l&eacute;ments injurieux ou obsc&egrave;nes susceptibles de porter atteinte aux bonnes m&oelig;urs.</p>
                                <p><strong>12.1 Gestion des r&eacute;clamations, R&eacute;tractation, Retour</strong></p>
                                <p>Le r&egrave;glement des &eacute;ventuelles r&eacute;clamations survenues entre Utilisateurs &agrave; propos de l&rsquo;achat, de la vente ou de la recherche d&rsquo;objets sur la Plateforme est de leur seule responsabilit&eacute;.</p>
                                <p>Toutefois, la Soci&eacute;t&eacute; met &agrave; disposition de ses Utilisateurs une proc&eacute;dure de r&egrave;glement des r&eacute;clamations qui d&eacute;termine notamment les &eacute;ventuelles conditions de remboursement des fonds avanc&eacute;s par l&rsquo;Client pour s&rsquo;acquitter du prix du Produit, de la commission de service pr&eacute;lev&eacute;e par la Soci&eacute;t&eacute;, des fonds expos&eacute;s pour l&rsquo;exp&eacute;dition du Produit.</p>
                                <p><strong>R</strong><strong>&egrave;</strong><strong>gles g&eacute;n&eacute;</strong><strong>rales</strong></p>
                                <p>L&rsquo;exercice par un Utilisateur de la proc&eacute;dure de gestion des r&eacute;clamations ne modifie pas les pr&eacute;sentes s&rsquo;agissant notamment de la limitation de la responsabilit&eacute; de la Soci&eacute;t&eacute; qui reste d&eacute;gag&eacute;e de toute responsabilit&eacute; vis-&agrave;-vis des r&eacute;clamations, demandes ou dommages et int&eacute;r&ecirc;ts d&eacute;coulant de r&eacute;clamations avec d&rsquo;autres Utilisateurs ou tiers.</p>
                                <p>Les signalements de r&eacute;clamations sont &agrave; adresser par mail &agrave; l&rsquo;adresse contact@behalal.fr ou par Lettre recommand&eacute;e avec accus&eacute; de r&eacute;ception (LRAR) avec toutes les pr&eacute;cisions n&eacute;cessaires &agrave; leur compr&eacute;hension.</p>
                                <p>La Soci&eacute;t&eacute; est seule arbitre des r&eacute;clamations qui lui sont adress&eacute;es. Elle peut notamment et sans avoir &agrave; s&rsquo;en expliquer, les rejeter si elle les estime infond&eacute;es. La Soci&eacute;t&eacute; n&rsquo;assure aucune garantie de r&egrave;glement des r&eacute;clamations.</p>
                                <p>La proc&eacute;dure de traitement des r&eacute;clamations ne s&rsquo;exerce que dans les conditions suivantes :</p>
                                <p>&ndash; le Commer&ccedil;ant a enregistr&eacute; sur son Compte le num&eacute;ro de suivi de livraison et l&rsquo;Client a confirm&eacute; la r&eacute;ception du produit sur son Compte.</p>
                                <p>&ndash; l&rsquo;Client qui souhaite b&eacute;n&eacute;ficier de la proc&eacute;dure de gestion des r&eacute;clamations peut le faire dans les 48 heures apr&egrave;s confirmation de sa r&eacute;ception.</p>
                                <p>&ndash; la r&eacute;clamation est accompagn&eacute;e des pi&egrave;ces ou &eacute;l&eacute;ments justificatifs (bon de livraison, photos, etc.) qui en permettent l&rsquo;appr&eacute;ciation par la Soci&eacute;t&eacute;.</p>
                                <p><strong>Droit de r&eacute;tractation</strong></p>
                                <p>Le droit de r&eacute;tractation d&rsquo;un Utilisateur ne s&rsquo;exerce pas s&rsquo;agissant de produits p&eacute;rissables.</p>
                                <p><strong>D&eacute;gradation lors de la livraison</strong></p>
                                <p>Les r&eacute;clamations pour d&eacute;gradation du Produit lors de la livraison ne s&rsquo;exercent que si le colis est endommag&eacute;. Il est de la responsabilit&eacute; de l&rsquo;Client de ne pas accepter le colis et d&rsquo;adresser une r&eacute;clamation au coursier.</p>
                                <p>Lorsque l&rsquo;Client adresse sa r&eacute;clamation &agrave; la Soci&eacute;t&eacute;, celle-ci informe le Commer&ccedil;ant.</p>
                                <p><strong>Non-conformit</strong><strong>&eacute; du Produit</strong></p>
                                <p>Les r&eacute;clamations relatives &agrave; la non-conformit&eacute; d&rsquo;un produit couvrent les cas suivants :</p>
                                <p>&ndash; le Produit a &eacute;t&eacute; mal emball&eacute; par le Commer&ccedil;ant et a &eacute;t&eacute; alt&eacute;r&eacute; pendant le transport ;</p>
                                <p>&ndash; le Produit n&rsquo;est pas conforme &agrave; la description de l&rsquo;annonce.</p>
                                <p>La Soci&eacute;t&eacute;, inform&eacute;e d&rsquo;une r&eacute;clamation pour non-conformit&eacute;, propose au Commer&ccedil;ant de d&eacute;dommager l&rsquo;Client en r&eacute;duisant le prix du Produit.</p>
                                <p>Si les deux Utilisateurs acceptent le montant du d&eacute;dommagement, la transaction est finalis&eacute;e selon les modalit&eacute;s suivantes :</p>
                                <p>&ndash; Le prix de l&rsquo;objet r&eacute;duit du montant du d&eacute;dommagement est vers&eacute; au Commer&ccedil;ant,</p>
                                <p>&ndash; Les frais d&rsquo;exp&eacute;dition sont revers&eacute;s au Commer&ccedil;ant,</p>
                                <p>&ndash; La commission est conserv&eacute;e par la Soci&eacute;t&eacute;.</p>
                                <p>&nbsp;</p>
                                <p><strong>ARTICLE 13 : PROPRI&Eacute;T</strong><strong>&Eacute; </strong><strong>INTELLECTUELLE</strong></p>
                                <p>Les Utilisateurs sont inform&eacute;s que la Plateforme et l&rsquo;ensemble des &eacute;l&eacute;ments (photographies, vid&eacute;os, textes, contenus audios, etc.) la composant sont couverts par des droits de propri&eacute;t&eacute; intellectuelle dont la Soci&eacute;t&eacute; est seule titulaire.</p>
                                <p>Cela inclut &eacute;galement l&rsquo;ensemble des d&eacute;nominations utilis&eacute;es pour d&eacute;signer la Plateforme et le Site.</p>
                                <p>&Agrave; cet &eacute;gard, tout acte de reproduction ou d&rsquo;utilisation non autoris&eacute; par la Soci&eacute;t&eacute; constitue un acte de contrefa&ccedil;on qui peut &ecirc;tre poursuivi comme tel tant sur le plan civil que sur le plan p&eacute;nal.</p>
                                <p>&Agrave; cet &eacute;gard, la Soci&eacute;t&eacute; autorise uniquement les Utilisateurs &agrave; utiliser les &eacute;l&eacute;ments couverts par un droit de propri&eacute;t&eacute; intellectuelle aux seules fins d&rsquo;ex&eacute;cution des pr&eacute;sentes et pendant la dur&eacute;e d&rsquo;application des pr&eacute;sentes.</p>
                                <p>Les Utilisateurs s&rsquo;engagent &agrave; ne modifier aucun de ces &eacute;l&eacute;ments d&rsquo;aucune mani&egrave;re ou &agrave; en faire une utilisation non conforme aux pr&eacute;sentes.</p>
                                <p><strong>13.1 Propri</strong><strong>&eacute;t&eacute; des annonces</strong></p>
                                <p>Par ailleurs, la Soci&eacute;t&eacute; reconna&icirc;t et accepte que les contenus des annonces publi&eacute;es par les Commer&ccedil;ants sur la Plateforme leur appartiennent et qu&rsquo;ils d&eacute;tiennent l&rsquo;ensemble des droits de propri&eacute;t&eacute; intellectuelle sur ces contenus.</p>
                                <p>Toutefois, aux fins de permettre l&rsquo;exploitation de la Plateforme et la publication de ces annonces, les Commer&ccedil;ants conc&egrave;dent express&eacute;ment &agrave; la Soci&eacute;t&eacute; :</p>
                                <p>&ndash; le droit d&rsquo;exploitation non exclusif, transf&eacute;rable, sous licenciable, &agrave; titre gracieux, pour le monde entier concernant l&rsquo;ensemble du contenu des annonces et notamment sur les photographies, textes, vid&eacute;os, illustrations, marques, logotypes, titres (ci-apr&egrave;s le &laquo; Contenu &raquo;), au fur et &agrave; mesure de leur publication sur la Plateforme ainsi (ii) qu&rsquo;une licence sur l&rsquo;ensemble des droits de propri&eacute;t&eacute; intellectuelle aff&eacute;rant aux annonces et notamment sur les droits d&rsquo;auteurs sur les &eacute;l&eacute;ments utilis&eacute;s dans son annonce, tels que les photographies, textes, vid&eacute;os, dessins, illustrations, et ce pour toute la dur&eacute;e l&eacute;gale de ses droits de propri&eacute;t&eacute; intellectuelle et pour le monde entier.</p>
                                <p>Les droits ainsi conc&eacute;d&eacute;s incluent le droit de reproduire, repr&eacute;senter, diffuser, adapter, modifier, r&eacute;aliser une &oelig;uvre d&eacute;riv&eacute;e, traduire tout ou partie du contenu par tous proc&eacute;d&eacute;s, sous quelque forme que ce soit et sur tous supports connus ou inconnus &agrave; ce jour dans le cadre de l&rsquo;activit&eacute; de la Soci&eacute;t&eacute; et ce &agrave; des fins commerciales ou non et notamment publicitaires, ainsi que dans le cadre d&rsquo;une diffusion sur les r&eacute;seaux sociaux le cas &eacute;ch&eacute;ant.</p>
                                <p>Cela comprend, en outre, le droit pour la Soci&eacute;t&eacute; d&rsquo;utiliser les services de prestataires tiers pour diffuser et traiter les photographies des Produits ; ce que les Utilisateurs acceptent.</p>
                                <p>&nbsp;</p>
                                <p><strong>ARTICLE 14 : OBLIGATIONS DE L</strong><strong>&rsquo;</strong><strong>UTILISATEUR</strong></p>
                                <p>Dans le cadre de leur utilisation et de l&rsquo;acc&egrave;s &agrave; la Plateforme, les Utilisateurs s&rsquo;engagent :</p>
                                <p>&ndash; &agrave; ne pas conclure de transaction en dehors de la Plateforme et en direct avec d&rsquo;autres Utilisateurs dont l&rsquo;Annonce aurait &eacute;t&eacute; publi&eacute;e sur la Plateforme ;</p>
                                <p>&ndash; &agrave; ne pas acc&eacute;der &agrave; la Plateforme &agrave; des fins illicites ou contraires aux bonnes m&oelig;urs ;</p>
                                <p>&ndash; &agrave; ne pas publier de contenu en violation des droits d&rsquo;un tiers notamment des droits de</p>
                                <p>propri&eacute;t&eacute; intellectuelle ;</p>
                                <p>&ndash; &agrave; fournir des informations exactes et v&eacute;rifiables concernant leur qualit&eacute; de</p>
                                <p>professionnel notamment ;</p>
                                <p>&ndash; &agrave; ne pas fournir ou publier des &eacute;l&eacute;ments qui sont contraires &agrave; l&rsquo;ordre public et aux</p>
                                <p>bonnes m&oelig;urs,</p>
                                <p>&ndash; &agrave; ne pas perturber le fonctionnement normal du Site et de la Plateforme.</p>
                                <p>&nbsp;</p>
                                <p><strong>ARTICLE 15 : DONN</strong><strong>&Eacute;</strong><strong>ES PERSONNELLES</strong></p>
                                <p>Les Utilisateurs sont inform&eacute;s que la Soci&eacute;t&eacute; collecte des donn&eacute;es &agrave; caract&egrave;re personnel les concernant aux seules fins de permettre l&rsquo;ex&eacute;cution des pr&eacute;sentes et la mise &agrave; disposition de la Plateforme.</p>
                                <p>Ces donn&eacute;es &agrave; caract&egrave;re personnel sont collect&eacute;es et trait&eacute;es conform&eacute;ment &agrave; la l&eacute;gislation applicable et notamment la loi information et libert&eacute; n&deg; 78-17 du 6 janvier 1978.</p>
                                <p><strong>Quelles donn&eacute;es &agrave; caract</strong><strong>&egrave;</strong><strong>re personnel sont collect&eacute;es ?</strong></p>
                                <p>La Soci&eacute;t&eacute; collecte et traite les donn&eacute;es suivantes relatives aux Utilisateurs : civilit&eacute;, nom, pr&eacute;nom, date de naissance, adresse postale, adresse &eacute;lectronique, adresse IP, t&eacute;l&eacute;phone, ainsi que les informations recueillies &agrave; l&rsquo;aide de cookies.</p>
                                <p><strong>Pour quelle raison les donn&eacute;es sont-elles collect&eacute;es ?</strong></p>
                                <p>Les donn&eacute;es &agrave; caract&egrave;re personnel sont collect&eacute;es aux fins de permettre &agrave; la Soci&eacute;t&eacute; de fournir ses services sur le Site et la Plateforme.</p>
                                <p>Il est rappel&eacute; aux Utilisateurs que les donn&eacute;es ainsi collect&eacute;es visent &agrave; permettre le bon fonctionnement du Site et de la Plateforme.</p>
                                <p>En outre, les Utilisateurs sont inform&eacute;s que seules les donn&eacute;es strictement n&eacute;cessaires aux finalit&eacute;s recherch&eacute;es sont collect&eacute;es. &Agrave; cet &eacute;gard, la Soci&eacute;t&eacute; applique le principe de la minimisation de la collecte des donn&eacute;es. La Soci&eacute;t&eacute; ne collectera pas plus de donn&eacute;es que ce dont elle a besoin pour assurer la fourniture du Site et de la Plateforme.</p>
                                <p><strong>Quelle dur&eacute;e de conservation ?</strong></p>
                                <p>Les donn&eacute;es &agrave; caract&egrave;re personnel sont collect&eacute;es et trait&eacute;es uniquement afin de r&eacute;pondre aux objectifs poursuivis par la Soci&eacute;t&eacute;.</p>
                                <p>Dans ce cadre, la Soci&eacute;t&eacute; ne conservera pas les donn&eacute;es collect&eacute;es pour une dur&eacute;e exc&eacute;dant l&rsquo;atteinte de l&rsquo;objectif qu&rsquo;elle s&rsquo;est fix&eacute;e.</p>
                                <p><strong>Les droits des personnes concern&eacute;es ?</strong></p>
                                <p>La Soci&eacute;t&eacute; s&rsquo;engage &agrave; assurer un traitement des donn&eacute;es conforme aux droits des personnes concern&eacute;es par un traitement de donn&eacute;es &agrave; caract&egrave;re personnel.</p>
                                <p>Ainsi, la Soci&eacute;t&eacute; garantit que les Utilisateurs b&eacute;n&eacute;ficient notamment d&rsquo;un droit d&rsquo;acc&egrave;s, de suppression, de modification, d&rsquo;opposition et de rectification concernant les donn&eacute;es collect&eacute;es les concernant.</p>
                                <p><strong>Les transferts de donn&eacute;es</strong></p>
                                <p>Les Utilisateurs sont inform&eacute;s de ce que leurs donn&eacute;es sont susceptibles d&rsquo;&ecirc;tre communiqu&eacute;es &agrave; des tiers domicili&eacute;s dans l&rsquo;Union europ&eacute;enne ou dans des &Eacute;tats assurant un niveau &eacute;quivalent de protection et ce, aux seules fins de permettre le bon fonctionnement du Site et de la Plateforme.</p>
                                <p><strong>La s</strong><strong>&eacute;curit&eacute; des donn&eacute;es &agrave; caract</strong><strong>&egrave;</strong><strong>re personnel</strong></p>
                                <p>La Soci&eacute;t&eacute; assure la s&eacute;curit&eacute; des donn&eacute;es &agrave; caract&egrave;re personnel concernant les Utilisateurs en prenant toutes les mesures appropri&eacute;es de fa&ccedil;on &agrave; garantir l&rsquo;absence d&rsquo;intrusion dans ses fichiers.</p>
                                <p>Ce faisant, la Soci&eacute;t&eacute; contr&ocirc;le notamment l&rsquo;acc&egrave;s aux donn&eacute;es des Utilisateurs.</p>
                                <p><strong>Cookies</strong></p>
                                <p>La Soci&eacute;t&eacute; utilise des cookies pour lui permettre de r&eacute;aliser des statistiques de visites du Site ainsi que pour assurer un fonctionnement optimal du Site et de la Plateforme et la diffusion &eacute;ventuelle de publicit&eacute;s cibl&eacute;es.</p>
                                <p>Par l&rsquo;application des pr&eacute;sentes, les Utilisateurs consentent au d&eacute;p&ocirc;t de cookies sur leur mat&eacute;riel informatique.</p>
                                <p><strong>Newsletter</strong></p>
                                <p>Dans le cadre de l&rsquo;utilisation de la Plateforme, l&rsquo;Utilisateur accepte de recevoir des emails de la Plateforme, sous forme de newsletter ou autre.</p>
                                <p>&nbsp;</p>
                                <p><strong>ARTICLE 16 : MODIFICATION DES CGV</strong></p>
                                <p>Les Utilisateurs sont inform&eacute;s que la Soci&eacute;t&eacute; pourra proc&eacute;der &agrave; des modifications des CGU &agrave; sa discr&eacute;tion ou pour r&eacute;pondre &agrave; la l&eacute;gislation applicable.</p>
                                <p>&Agrave; ce titre, les Utilisateurs s&rsquo;engagent &agrave; consulter r&eacute;guli&egrave;rement les CGV.</p>
                                <p>Toute modification est consid&eacute;r&eacute;e comme accept&eacute;e d&egrave;s lors que les Utilisateurs continuent d&rsquo;utiliser la Plateforme.</p>
                                <p>&nbsp;</p>
                                <p><strong>ARTICLE 17 : R&Eacute;</strong><strong>SILIATION</strong></p>
                                <p>Chaque Utilisateur peut r&eacute;silier les pr&eacute;sentes &agrave; tout moment en cl&ocirc;turant son Compte en ligne directement depuis l&rsquo;interface de son Compte.</p>
                                <p>En outre, la Soci&eacute;t&eacute; peut &eacute;galement mettre un terme aux pr&eacute;sentes &agrave; tout moment en cas de violation des pr&eacute;sentes par un Utilisateur apr&egrave;s une notification et un d&eacute;lai de pr&eacute;avis de 5 jours ouvr&eacute;s.</p>
                                <p>&nbsp;</p>
                                <p><strong>ARTICLE 18 : LOI APPLICABLE ET JURIDICTION COMP&Eacute;</strong><strong>TENTE</strong></p>
                                <p>Les pr&eacute;sentes CGU sont r&eacute;gies par la l&eacute;gislation fran&ccedil;aise.</p>
                                <p>En cas de difficult&eacute; concernant leur interpr&eacute;tation ou leur ex&eacute;cution, les parties entendent donner comp&eacute;tence &agrave; toute juridiction comp&eacute;tente selon le droit commun applicable en la mati&egrave;re.</p>
                                <p>&nbsp;</p>
                                <p><strong>ARTICLE 19 : LIENS HYPERTEXTES</strong></p>
                                <p>Le Site peut contenir des liens dirigeant les Utilisateurs vers d&rsquo;autres sites Internet qui ne sont pas contr&ocirc;l&eacute;s par la Soci&eacute;t&eacute;.</p>
                                <p>Ce faisant, la Soci&eacute;t&eacute; n&rsquo;assume aucune responsabilit&eacute; quant &agrave; ces liens ni au contenu des sites ainsi vis&eacute;s.</p>
                                <p>Il ne s&rsquo;agit que de liens donn&eacute;s &agrave; titre informatif ; ce que les Utilisateurs reconnaissent et acceptent.</p>
                                <p>&nbsp;</p>
                                <p><strong>ARTICLE 20 : DISPOSITIONS DIVERSES</strong></p>
                                <p>Il est consid&eacute;r&eacute; qu&rsquo;en application de la l&eacute;gislation applicable, les Utilisateurs ont re&ccedil;u une information claire, loyale et transparente contenue notamment dans les pr&eacute;sentes ; ce qu&rsquo;ils reconnaissent et acceptent.</p>



                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default Cgv;
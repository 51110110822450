import React, { useState, useHook, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { productDetails, cart, refreshCart } from '../actions/index';
import '../styles/detail-page.css';
import Cookies from 'js-cookie';
import { get, post } from "../adapters/index";
import { useTranslation } from "react-i18next";
import "../translations/i18n";
import { useParams } from 'react-router';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Cache, { setCache } from '../utility/Cache';
import addCart, { isExist, removeCart, decrementCart, incrementCart } from '../utility/Cart';
import moment from 'moment';

import jBox from 'jbox';
import 'jbox/dist/jBox.all.css';

const CartList = (props) => {

    /*if (props[0] == undefined || props[0].product_image == undefined)  {
        props[0] = props;
    }*/

    console.log('props', props.data.product_name);

    const { t } = useTranslation();
    const [qty, setQty] = useState(props.data.product_qty);
    const [unitPrice, setUnitPrice] = useState(props.data.product_min_price);
    const [totalPrice, setTotalPrice] = useState(props.data.total);
    const [cartId, setCartId] = useState(props.data.cart_id);    
    const dispatch = useDispatch();

    const minus = () => {
        setQty(prevQty => prevQty - 1);
        decrementCart(props.data.product_id, 1);
        dispatch(refreshCart(moment('x')));

        if (Cookies.get('bhUser') != undefined) {
            const form = new FormData();
            form.append('butcher_id', Cookies.get('bhId'));
            form.append('cart_id', cartId);
            form.append('item_id', props.data.product_id);
            form.append('quantity', 1);

            post('cart/decrement', form).then(async function (response) {
                //setItems(response.data.data);
            }).catch(function (error) {

            });
        }
    }

    const increment = () => {
        setQty(prevQty => prevQty + 1);
        incrementCart(props.data.product_id, 1);
        dispatch(refreshCart(moment('x')));

        if (Cookies.get('bhUser') != undefined) {
            const form = new FormData();
            form.append('butcher_id', Cookies.get('bhId'));
            form.append('cart_id', cartId);
            form.append('item_id', props.data.product_id);
            form.append('quantity', 1);

            post('cart/increment', form).then(async function (response) {
                //setItems(response.data.data);
            }).catch(function (error) {

            });
        }
    }

    const del = () => {
        removeCart(props.data.product_id, props.data.product_name);
        setQty(0);
        dispatch(cart(-1))
        //dispatch(refreshCart(moment('x')))

        if (Cookies.get('bhUser') != undefined) {
            const form = new FormData();
            form.append('butcher_id', Cookies.get('bhId').toString());
            form.append('cart_id', cartId);
            form.append('item_id', props.data.cart_item_id);
            form.append('quantity', 1);

            for (var key of form.entries()) {
                //console.log(key[0] + ', ' + key[1]);
            }

            post('cart/delete_item', form).then(async function (response) {
                //setItems(response.data.data);
            }).catch(function (error) {});
        }
    }

    useEffect(function () {
        var totalPrice = unitPrice * qty;
        setTotalPrice(totalPrice);
    }, [qty]);

    const seo_url = (url) => {
        return url.toString()               // Convert to string
            .normalize('NFD')               // Change diacritics
            .replace(/[\u0300-\u036f]/g, '') // Remove illegal characters
            .replace(/\s+/g, '-')            // Change whitespace to dashes
            .toLowerCase()                  // Change to lowercase
            .replace(/&/g, '-and-')          // Replace ampersand
            .replace(/[^a-z0-9\-]/g, '')     // Remove anything that is not a letter, number or dash
            .replace(/-+/g, '-')             // Remove duplicate dashes
            .replace(/^-*/, '')              // Remove starting dashes
            .replace(/-*$/, '');
    }

    return (
        <>
            {
                qty > 0
                    ?
                    <tr>
                        <td class="product-thumbnail">
                            <a href={"/product/"+props.data.product_id+"/" + seo_url(props.data.product_name)}><img style={{ padding: 10 }} class="img-fluid" src={process.env.REACT_APP_IMG_URL + props.data.product_image.replace('/images', '/images/thumbnail')} alt="" /></a>
                        </td>
                        <td class="product-name">
                            <a href={"/product/"+props.data.product_id+"/" + seo_url(props.data.product_name)}><h6>{props.data.product_name} {props.data.qty_type}</h6></a>
                        </td>
                        <td class="product-price-cart">
                            <h6>{t('currency_symbol')} {unitPrice}</h6>
                        </td>
                        <td class="product-quantity">
                            <div class="cart-plus-minus">
                                <button class="dec qtybutton" onClick={(e) => minus()}>-</button>
                                <input class="cart-plus-minus-box" type="text" value={qty} style={{textAlign: 'center'}}/>
                                <button class="inc qtybutton" onClick={(e) => increment()}>+</button>
                            </div>
                        </td>
                        <td><h6>{t('currency_symbol')} {totalPrice}</h6></td>
                        <td class="product-remove">
                            <span class="fs1" aria-hidden="true" data-icon="" onClick={(e) => del()}></span>
                        </td>
                    </tr>
                    :
                    null
            }
        </>
    )
}

export default CartList;
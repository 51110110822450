import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Header from "../components/Header"
import Footer from "../components/Footer"
import '../styles/detail-page.css';
import Cookies from 'js-cookie';
import { post } from "../adapters/index";
import { useTranslation } from "react-i18next";
import "../translations/i18n";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import CartList from '../components/CartList'
import {reactLocalStorage} from 'reactjs-localstorage';
import Checkout from './Checkout';
import { openLogin, openLoginAfter } from '../actions/index';
import moment from 'moment';

const Cart = () => {

    const { t } = useTranslation();
    const [items, setItems] = useState([]);
    const [total, setTotal] = useState(0);
    const refresh = useSelector(state => state.cart.refresh);
    const isUserLogin = useSelector(state => state.login);
    const dispatch = useDispatch();

    useEffect(function () {
        FetchTotalData();
    }, [refresh]);

    useEffect(function () {
        if (Cookies.get('bhUser') == undefined) {
            FetchData();
        }
        else {
            FetchUserCartData();
        }
    }, []);


    useEffect(function () {
        var total = 0;
        var cartData = [];
        items.map((i, k) => 
            total = parseFloat(i.product_price) + total
        );

        items.map((i, k) => 
            cartData.push({
                product: i.product_id,
                qty: i.product_qty
            })
        );

        var objectData = {
            data : cartData,
            expiry_time : moment().endOf('day').format("YYYY-MM-DD HH:mm:ss")
        }

        reactLocalStorage.remove('bhCart');
        reactLocalStorage.setObject('bhCart', JSON.stringify(objectData));

        if (!isNaN(total))
            setTotal(total);

    }, [items]);

    const FetchTotalData = () => {
        var bhCart = reactLocalStorage.getObject('bhCart');
        if (Object.keys(bhCart).length == 0) {
            setTotal(0)
            return null;

        }

        if (Cookies.get('bhUser') == undefined) {
            var cart = JSON.parse(bhCart);
            const form = new FormData();
            form.append('butcher_id', Cookies.get('bhId'));
            form.append('cart', JSON.stringify(cart.data));

            post('wb/cart/details', form).then(async function (response) {
                setTotal(response.data.total_product_price);
            }).catch(function (error) {});
        }
        else {
            const form = new FormData();
            form.append('butcher_id', Cookies.get('bhId'));

            post('cart/view', form).then(async function (response) {
                setTotal(response.data.total_product_price);
            }).catch(function (error) {});
        }
    }


    const FetchData = () => {
        var bhCart = reactLocalStorage.getObject('bhCart');
        if (Object.keys(bhCart).length == 0) {
            return null;

        }
        var cart = JSON.parse(bhCart);
        const form = new FormData();
		form.append('butcher_id', Cookies.get('bhId'));
		form.append('cart', JSON.stringify(cart.data));

		post('wb/cart/details', form).then(async function (response) {
			setItems(response.data.data);
		}).catch(function (error) {

		});
    }

    const FetchUserCartData = () => {
        const form = new FormData();
		form.append('butcher_id', Cookies.get('bhId'));

		post('cart/view', form).then(async function (response) {
			setItems(response.data.data);
		}).catch(function (error) {});
    }

    const checkout = () => {
        if (isUserLogin.is_login == false || isUserLogin.is_login == undefined) {
            dispatch(openLogin(true));
            dispatch(openLoginAfter('checkout'));
        }
        else {
            window.location = '/checkout';
        }
    }

    return (
        <div>
            <Header />
            <div class="container margin_detail">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="detail_page_head clearfix">
                            <div class="title">
                                <h1>{t('your_cart')}</h1>
                            </div>
                        </div>

                        <hr style={{ border: '1px dotted #a4a4a4' }} />

                        <div class="table-content table-responsive cart-table-content">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Image</th>
                                        <th>{t('product_name')}</th>
                                        <th>{t('unit_price')}</th>
                                        <th>{t('quantity')}</th>
                                        <th>total</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        items.map((i, k) =>
                                            <CartList data={i} key={k}/>
                                        )
                                    }

                                    {
                                        items.length == 0
                                        ?
                                        <tr>
                                            <td colspan="5">
                                                <span style={{display: 'block',padding: 20}}>{t('no_items')}</span>
                                            </td>
                                        </tr>
                                        :
                                        null
                                    }
                                    
                                </tbody>
                            </table>
                        </div>

                    </div>

                    <div class="col-lg-4" id="sidebar_fixed" style={{marginTop:85}}>
                        <div class="box_booking theiaStickySidebar">
                            <div class="head">
                                <h3>{t('checkout')}</h3>
                                <div class="offer">Total {t('currency_symbol')} {total}</div>
                            </div>
                            <div class="main">
                                {
                                    total > 0
                                    ?
                                    <a href="#" onClick={(e)=>checkout()} aria-hidden="true" data-icon="" class="btn_1 full-width mb_5"> {t('checkout')}</a>
                                    :
                                    null
                                }
                                
                                <a style={{marginTop: 40, marginBottom: 40}} href="/" class="btn_1 full-width outline wishlist"><i class="icon-food_icon_shop fs1"></i> {t('continue_shopping')}</a>
                            </div>
                        </div>
                        <ul class="share-buttons">
                            <li><a class="fb-share" href="#0"><i class="social_facebook"></i> Share</a></li>
                            <li><a class="twitter-share" href="#0"><i class="social_twitter"></i> Share</a></li>
                            <li><a class="gplus-share" href="#0"><i class="social_googleplus"></i> Share</a></li>
                        </ul>
                    </div>

                </div>
            </div>
            <Footer />
        </div>

    )
}

export default Cart;
import React, { useState, useEffect, useRef, useParams } from 'react';
import HeaderHome from "../components/HeaderHome"
import Footer from "../components/Footer"
import '../styles/submit.css';
import about from '../images/about.jpg';
import about1 from '../images/about_1.svg';
import arrow_about from '../images/arrow_about.png';
import about_2 from '../images/about_2.svg';
import about_3 from '../images/about_3.svg';
import thumbnail from '../video/thumbnail.jpg';
import video from '../video/intro.mp4';
import Cookies from 'js-cookie';
import { post, get } from "../adapters/index";

const About = () => {

    Element.prototype.insertAfter = function(newEl) {
        this.parentNode.insertBefore(newEl, this.nextSibling);
    }

    const add = () => {

        var error = false;
        var ele = document.querySelectorAll(".error");
        
        for (var j = 0; j < ele.length; j++) {
            ele[j].remove();
        }

        var name_register = document.getElementById('name_register');
        var email_register = document.getElementById('email_register');
        var telephone_register = document.getElementById('telephone_register');
        var restaurantname_register = document.getElementById('restaurantname_register');
        var address_register = document.getElementById('address_register');
        var city_register = document.getElementById('city_register');

        if (name_register.value.length == 0) {
            var newEll = document.createElement("div");
            newEll.classList.add('error');
            newEll.innerHTML = "s'il vous plaît entrez le nom";
            name_register.insertAfter(newEll);
            error = true;
        }

        if (email_register.value.length == 0) {
            var newEll = document.createElement("div");
            newEll.classList.add('error');
            newEll.innerHTML = "veuillez entrer l'adresse e-mail";
            email_register.insertAfter(newEll);
            error = true;
        }

        if (telephone_register.value.length == 0) {
            var newEll = document.createElement("div");
            newEll.classList.add('error');
            newEll.innerHTML = "veuillez entrer le numéro de téléphone";
            telephone_register.insertAfter(newEll);
            error = true;
        }

        if (restaurantname_register.value.length == 0) {
            var newEll = document.createElement("div");
            newEll.classList.add('error');
            newEll.innerHTML = "Veuillez saisir le nom de la boucherie";
            restaurantname_register.insertAfter(newEll);
            error = true;
        }

        if (address_register.value.length == 0) {
            var newEll = document.createElement("div");
            newEll.classList.add('error');
            newEll.innerHTML = "Veuillez saisir l'adresse";
            address_register.insertAfter(newEll);
            error = true;
        }

        if (city_register.value.length == 0) {
            var newEll = document.createElement("div");
            newEll.classList.add('error');
            newEll.innerHTML = "Veuillez sélectionner la ville";
            city_register.insertAfter(newEll);
            error = true;
        }

        
        
        var details = {
            name_register : name_register.value,
            email_register : email_register.value,
            telephone_register: telephone_register.value,
            restaurantname_register: restaurantname_register,
            address_register: address_register,
            city_register:city_register
        }

        if (error === true) {
            return false;
        }

        const form = new FormData();
        form.append('butcher_id', Cookies.get('bhId'));
        form.append('page', 'about');
        form.append('details', JSON.stringify(details));

        post('/wb/admin/contactus', form).then(async function (response) {
            name_register.value = '';
            email_register.value = '';
            telephone_register.value = '';
            restaurantname_register.value = '';
            address_register.value = '';
            city_register.value = '';
            alert("Soumis avec succès")
            
        }).catch(function (error) {
        });

    }

    return (
        <div>
            <HeaderHome />
            <div class="hero_single inner_pages background-image" data-background={"url("+about+")"}>
                <div class="opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.6)">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-xl-9 col-lg-10 col-md-8">
                                <h1>Découvrez</h1>
                                <p>nos boucheries sur Paris et en Ile-de-France</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div class="container margin_60_40">
                    <div class="main_title center">
                        <span><em></em></span>
                        <h2>Qu’est ce que B.e Halal ?</h2>
                        <p>B.e Halal est votre nouvelle plate-forme de livraison de viande qui vous permet de commander vos viandes préférées dans vos boucheries locales en quelques clics!</p>
                    </div>

                    <div class="row justify-content-center align-items-center add_bottom_15">
                        <div class="col-lg-5">
                            <div class="box_about">
                                <h3>Téléchargez & installez l’application</h3>
                                <p class="lead">Disponible sur Apple store et Play store</p>
                                <p>B.e Halal est disponible sur Apple store et Play store. Renseignez vos Informations & voilà …vous faites partie de la communauté B.e Halal.</p>
                                <img src={arrow_about} alt="" class="arrow_1" />
                            </div>
                        </div>
                        <div class="col-lg-5 pl-lg-5 text-center d-none d-lg-block">
                            <img src={about1} alt="" class="img-fluid" />
                        </div>
                    </div>
                    <div class="row justify-content-center align-items-center add_bottom_15">
                        <div class="col-lg-5 pr-lg-5 text-center d-none d-lg-block">
                            <img src={about_2} alt="" class="img-fluid" />
                        </div>
                        <div class="col-lg-5">
                            <div class="box_about">
                                <h3>Choisissez votre boucherie préférée</h3>
                                <p class="lead">Parmi tous nos artisans bouchers soigneusement sélectionnés</p>
                                <p>B.e Halal vous donne l'opportunité de choisir vos viandes, vos charcuteries, vos épices ainsi que toutes vos boissons ...sans bouger de chez vous.</p>
                                <img src="img/arrow_about.png" alt="" class="arrow_2" />
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center align-items-center">
                        <div class="col-lg-5">
                            <div class="box_about">
                                <h3>Faites livrer vos articles chez vous</h3>
                                <p class="lead">Faites livrer vos viandes de qualité en quelques clics</p>
                                <p>Une livraison fraîche et rapide, livré chez vous quand vous le souhaitez.</p>
                            </div>

                        </div>
                        <div class="col-lg-5 pl-lg-5 text-center d-none d-lg-block">
                            <img src={about_3} alt="" class="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="bg_offwhite">
                <div class="center container margin_60_40">
                    <div class="main_title center">
                        <span><em></em></span>
                        <h2>Jetez un œil à notre présentation
                        </h2>
                    </div>

                    <video class="center" controls width="600" height="400" autoplay loop preload="auto" poster={thumbnail}>
                        <source src={video} type="video/mp4" />
                        <p>Your browser doesn't support HTML video.</p>
                    </video>

                </div>
            </div>

            <section id="form">
                <div class="bg_gray pattern" id="submit">
                    <div class="container margin_60_40">
                        <div class="row justify-content-center">
                            <div class="col-lg-5">
                                <div class="text-center add_bottom_15">
                                    <h2>Vous êtes un boucher ?</h2>
                                    <p>Remplissez le formulaire, un membre B.e Halal vous contactera très rapidement pour en savoir plus sur votre boucherie</p>
                                </div>
                                <div id="message-register"></div>
                                <form method="post" action="javascript:void(0);" id="register">
                                    <h6>Coordonnés du boucher</h6>
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Nom & Prénom" name="name_register" id="name_register" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <input type="email" class="form-control" placeholder="Adresse e-mail" name="email_register" id="email_register" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row add_bottom_15">
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Numero de telephone" name="telephone_register" id="telephone_register" />
                                            </div>
                                        </div>
                                    </div>
                                    <h6>Coordonnées de la boucherie</h6>
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Nom de la boucherie" name="restaurantname_register" id="restaurantname_register" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Adresse postale" name="address_register" id="address_register" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row add_bottom_15">

                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <select class="form-control" name="city" id="city_register">
                                                    <option value="">Ville</option>
                                                    <option value="Paris">Paris</option>
                                                    <option value="Marseille">Marseille</option>
                                                    <option value="Lyon">Lyon</option>
                                                    <option value="Lille">Lille</option>
                                                    <option value="Nantes">Nantes</option>
                                                    <option value="Bordeaux">Bordeaux</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group text-center">
                                        <input type="submit" class="btn_1" value="Soumettre" id="submit-register" onClick={() => add()}></input>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div >
    )
}

export default About;
import React, { useState, useHook, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import HeaderHome from "../components/HeaderHome"
import Footer from "../components/Footer"

import bg_call_section from '../images/bg_call_section.jpg';
import banner_bg_desktop from '../images/banner_bg_desktop.jpg';

import veg from '../images/veg.png';
import non_veg from '../images/nonveg.png';

import { useTranslation } from "react-i18next";
import "../translations/i18n";
import LoadingOverlay from 'react-loading-overlay-ts';
import PulseLoader from 'react-spinners/PulseLoader'
import { loading } from '../actions/index';
import { useSelector, useDispatch } from 'react-redux';
import { get, post } from "../adapters/index";
import { categories, popular, ratings } from '../actions/index';

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Cookies from 'js-cookie';
import '../styles/css_skelton.css';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const Home = (props) => {

    const loading = useSelector(state => state.loading);
    const category = useSelector(state => state.categories);
    const popularProducts = useSelector(state => state.products.popular)
    const ratingProducts = useSelector(state => state.products.ratings)
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [askPincode, setAskPincode] = useState(false);
    const [savePincodeLoading, setSavePincodeLoading] = useState(false);
    const inputM = useRef(null);
    const inputO = useRef(null);
    const onOpenModal = () => setAskPincode(true);
    const onCloseModal = () => setAskPincode(false);
    const [pincodeInfo, setPincodeInfo] = useState('');
    //const [category, setCategory] = useState([]);

    const [c, setC] = useState([]);
    const [responsive, setResponsive] = useState({
        0: {
            items: 1,
        },
        450: {
            items: 2,
        },
        600: {
            items: 3,
        },
        1000: {
            items: 4,
        },
    });


    useEffect(function () {
        var c = window.client;

        /*navigator.geolocation.getCurrentPosition(
            function (position) {
                console.log(position);
            },
            function (error) {
                console.error("Error Code = " + error.code + " - " + error.message);

                if (error.code == 1 && Cookies.get('bhDelivery') == undefined) {
                    //setAskPincode(true);
                }
            }
        );*/

        if (Cookies.get('bhId') == undefined) {
            Cookies.set('bhId', process.env.REACT_APP_DEFAULT_ID, { expires: 2 });
        }
        _loadCategories();
        _loadMostPopular();
        _loadRatings();
    }, []);

    const _loadCategories = () => {
        const form = new FormData();
        form.append('butcher_id', Cookies.get('bhId'));

        post('wb/category/all', form).then(async function (response) {
            dispatch(categories(response.data.data))
        }).catch(function (error) {
        });
    }

    const _loadMostPopular = () => {
        const form = new FormData();
        form.append('butcher_id', Cookies.get('bhId'));

        post('wb/products/popular', form).then(async function (response) {
            dispatch(popular(response.data.data))
        }).catch(function (error) {
        });
    }

    const _loadRatings = () => {
        const form = new FormData();
        form.append('butcher_id', Cookies.get('bhId'));

        post('wb/products/ratings', form).then(async function (response) {
            dispatch(ratings(response.data.data))
        }).catch(function (error) {
        });
    }


    const set = () => {

        if (inputM.current.value.length != 5) {
            return false;
        }
        setSavePincodeLoading(true);

        const form = new FormData();
        form.append("pincode", inputM.current.value);
        post('customer/validate/pincode', form).then(async function (response) {
            setSavePincodeLoading(false);

            if (response.data.data.pincode == 'invalid') {
                setPincodeInfo(t('delivery_not'));
                Cookies.set('bhDelivery', false, { expires: 2 });
            }
            else {
                setAskPincode(false);
                Cookies.set('bhDelivery', true, { expires: 2 });

                if (response.data.data.id != 0) {
                    Cookies.set('bhId', response.data.data.id, { expires: 2 });
                }
            }
        }).catch(function (error) {
            setSavePincodeLoading(false);
        });

    }

    const seo_url = (url) => {
        return url.toString()               // Convert to string
            .normalize('NFD')               // Change diacritics
            .replace(/[\u0300-\u036f]/g, '') // Remove illegal characters
            .replace(/\s+/g, '-')            // Change whitespace to dashes
            .toLowerCase()                  // Change to lowercase
            .replace(/&/g, '-and-')          // Replace ampersand
            .replace(/[^a-z0-9\-]/g, '')     // Remove anything that is not a letter, number or dash
            .replace(/-+/g, '-')             // Remove duplicate dashes
            .replace(/^-*/, '')              // Remove starting dashes
            .replace(/-*$/, '');
    }

    return (

        <div>

            <LoadingOverlay
                active={loading}
                spinner={<PulseLoader />}
            >
                <HeaderHome />
                <main>
                    <Modal styles={{ modal: { backgroundColor: 'transparent', overflow: 'hidden' } }} open={askPincode} showCloseIcon={false} onClose={onCloseModal} blockScroll={true} center>
                        <div id="sign-in-dialog" className="zoom-anim-dialog">
                            <div className="modal_header">
                                <h3>{t("set_pincode")}</h3>
                            </div>
                            <div className="sign-in-wrapper">
                                <a href="#0" className="social_bt facebook" style={{ display: 'none' }}>Login with Facebook</a>
                                <a href="#0" className="social_bt google" style={{ display: 'none' }}>Login with Google</a>
                                <div className="divider" style={{ display: 'none' }}><span>Or</span></div>

                                <p>
                                    {t('pincodehelp')}
                                </p>

                                {
                                    pincodeInfo != ''
                                        ?
                                        <p style={{ color: '#1189ae', border: '1px solid #eeeded', padding: 10 }}>{pincodeInfo}</p>
                                        :
                                        null
                                }


                                <div className="form-group">
                                    <label>{t('pincode')}</label>
                                    <input type="number" ref={inputM} className="form-control" name="mobile" id="mobile" min="5" max="5" />
                                    <i className="fs1" aria-hidden="true" data-icon=""></i>
                                </div>

                                <div className="text-center">
                                    <button onClick={() => set()} className="btn_1 full-width mb_5 one"> {savePincodeLoading}
                                        {
                                            savePincodeLoading == false
                                                ?
                                                t('save')
                                                :
                                                <PulseLoader color="#ffffff" size={8} />
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal>


                    <div class="hero_single version_2">
                        <div class="opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.6)">
                            <div class="container">
                                <div class="row justify-content-center">
                                    <div class="col-xl-9 col-lg-10 col-md-8">
                                        <h1>B.e Halal </h1>
                                        <p>Tout le halal en 1 clic</p>
                                        <form method="get" action="/products">
                                            <div class="row no-gutters custom-search-input">
                                                <div class="col-lg-4">
                                                    <div class="form-group">
                                                        <input name="q" class="form-control" type="text" placeholder="Que cherchez-vous..." />
                                                        <i class="icon_search"></i>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <input name="location" class="form-control no_border_r" type="text" placeholder="Votre code postal..." />
                                                        <i class="icon_pin_alt"></i>
                                                    </div>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="submit" value="Recherche" />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="bg_gray">
                        <div class="container margin_60_40">
                            <div class="main_title center">
                                <span><em></em></span>
                                <h2>Qu’allez vous trouver ?</h2>
                                <p>Vos viandes favorites, vos charcuteries, vos épices, vos sauces, vos fromages, vos desserts & bien plus encore... à portée de main</p>
                            </div>

                            

                            <OwlCarousel responsive={responsive} nav items={5} dots={false} className='owl-theme categories_carousel_m' margin={10} >
                                {
                                    category.length > 0
                                        ?
                                        category.map((c, v) =>
                                            <div class="item">
                                                <a href={"/category/" + c.id + "/" + seo_url(c.category_name)} style={{display: 'flex',flexDirection: 'column', alignItems:'center'}}>
                                                    
                                                    {
                                                        c.image === null
                                                        ?
                                                        <i class="icon-food_icon_burrito"></i>
                                                        :
                                                        <img src={process.env.REACT_APP_IMG_URL + c.image} style={{width:90, marginBottom: 55}}/>
                                                    }
                                                    
                                                    <h3>{c.category_name}</h3>
                                                </a>
                                            </div>
                                        )
                                        :
                                        <div className="linear-background">&nbsp;</div>
                                }
                            </OwlCarousel>
                        </div>
                    </div>

                    <div class="container margin_60_40">
                        <div class="main_title">
                            <span><em></em></span>
                            <h2>Articles les plus populaires</h2>
                            <p>Découvrez nos articles les plus populaires et les plus vendus</p>
                            <a href="/products/popular"><font color="#0f8044">Voir tout</font></a>
                        </div>

                        {popularProducts.length > 0 ?
                            <OwlCarousel responsive={responsive} nav items={4} dots={false} className='owl-theme' margin={10} >
                                {

                                    popularProducts.map((p, k) => {
                                        return (
                                            <div className="item">
                                                <div className="strip">
                                                    <figure>
                                                        <img src={process.env.REACT_APP_IMG_URL + p.image} data-src={process.env.REACT_APP_IMG_URL + p.image} alt="" />
                                                        <a href={"/product/" + p.product_id + "/" + seo_url(p.name)} className="strip_info">
                                                            <small>{p.category_name}</small>
                                                            <div className="item_title">
                                                                <h3>{p.name}</h3>
                                                                <h4>{p.qty}</h4>
                                                                <small>{p.price} Euros</small>
                                                            </div>
                                                        </a>
                                                    </figure>
                                                    <ul>
                                                        <li>
                                                            <span>
                                                                {
                                                                    p.type == 1
                                                                        ?
                                                                        <img src={veg} alt="" />
                                                                        :
                                                                        <img src={non_veg} alt="" />
                                                                }

                                                            </span></li>
                                                        <li>
                                                            <div className="score"><span>{p.rated} Reviews</span><strong>{p.rating} &#9733;</strong></div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        )
                                    }

                                    )
                                }
                            </OwlCarousel>
                            :
                            <div>
                                <div class="item" style={{ float: 'left', width: 297.5, marginRight: 13 }}><div class="strip"><figure><img src="img/lazy-placeholder.png" data-src="img/location_1.jpg" class="owl-lazy" alt="" /><a href="#" class="strip_info"><small>...</small><div class="item_title"><h3>....</h3><h4></h4><small>... </small></div></a></figure><ul><li><span><img src="img/nonveg.png" alt="" /></span></li><li><div class="score"><span>...</span><strong>... ★</strong></div></li></ul></div></div>
                                <div class="item" style={{ float: 'left', width: 297.5, marginRight: 13 }}><div class="strip"><figure><img src="img/lazy-placeholder.png" data-src="img/location_1.jpg" class="owl-lazy" alt="" /><a href="#" class="strip_info"><small>...</small><div class="item_title"><h3>....</h3><h4></h4><small>... </small></div></a></figure><ul><li><span><img src="img/nonveg.png" alt="" /></span></li><li><div class="score"><span>...</span><strong>... ★</strong></div></li></ul></div></div>
                                <div class="item" style={{ float: 'left', width: 297.5, marginRight: 13 }}><div class="strip"><figure><img src="img/lazy-placeholder.png" data-src="img/location_1.jpg" class="owl-lazy" alt="" /><a href="#" class="strip_info"><small>...</small><div class="item_title"><h3>....</h3><h4></h4><small>... </small></div></a></figure><ul><li><span><img src="img/nonveg.png" alt="" /></span></li><li><div class="score"><span>...</span><strong>... ★</strong></div></li></ul></div></div>
                                <div class="item" style={{ float: 'left', width: 297.5, marginRight: 13 }}><div class="strip"><figure><img src="img/lazy-placeholder.png" data-src="img/location_1.jpg" class="owl-lazy" alt="" /><a href="#" class="strip_info"><small>...</small><div class="item_title"><h3>....</h3><h4></h4><small>... </small></div></a></figure><ul><li><span><img src="img/nonveg.png" alt="" /></span></li><li><div class="score"><span>...</span><strong>... ★</strong></div></li></ul></div></div>
                            </div>
                        }

                        <div class="banner lazy" data-bg={banner_bg_desktop} style={{ marginTop: 20, backgroundImage: "url(" + banner_bg_desktop + ")" }}>
                            <div class="wrapper d-flex align-items-center opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.2)">
                                <div>
                                    <small>B.e Halal</small>
                                    <h3>Choisissez vos produits préférés auprès de notre sélection B.e Halal</h3>
                                    <a href="grid-listing-filterscol.html" class="btn_1">En savoir plus</a>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <div class="main_title version_2">
                                    <span><em></em></span>
                                    <h2>Articles les plus notés</h2>
                                    <p>Vérifier sont les articles les plus notés</p>
                                    <a href="/products/ratings" class="btn_1">Voir tout</a>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="list_home">
                                    <div class="row">
                                        {
                                            ratingProducts.length > 0
                                                ?
                                                ratingProducts.map((p, k) =>
                                                    <div class="col-md-6">
                                                        <ul>
                                                            <li>
                                                                <a href={"/product/" + p.product_id + "/" + seo_url(p.name)}>
                                                                    <figure>
                                                                        <img src={process.env.REACT_APP_IMG_URL + p.image} data-src={process.env.REACT_APP_IMG_URL + p.image} alt="" />
                                                                    </figure>
                                                                    <div class="score"><strong>{p.rating} &#9733;</strong></div>
                                                                    <em>{p.category_name}</em>
                                                                    <h3>{p.name}</h3>
                                                                    <small>{p.qty}</small>
                                                                    <ul>
                                                                        <li><font color="#8cc04f"><b>{p.price} Euros</b></font></li>
                                                                    </ul>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                )

                                                :
                                                null
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                        <p class="text-center d-block d-md-block d-lg-none"><a href="" class="btn_1">Voir tout</a></p>
                    </div>

                    <div class="call_section lazy" data-bg={bg_call_section} style={{ backgroundImage: "url(" + bg_call_section + ")" }}>
                        <div class="container clearfix">
                            <div class="col-lg-5 col-md-6 float-right wow">
                                <div class="box_1">
                                    <h3>Devenir membre B.e Halal</h3>
                                    <p>Un produit digital, très demandé par les consommateurs musulmans</p>
                                    <a href="about.html#form" class="btn_1">S'inscrire maintenant</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </main>

                <Footer />
            </LoadingOverlay>
        </div>

    )
}

export default Home;
const cartReducer = (state = {count:0, refresh: false}, action) => {
    switch(action.type) {
        case 'ADD_CART':
            state.count = state.count + action.payLoad;
            return state;
        case 'REFRESH_CART':
            state.refresh = action.payLoad;
            return state;
        default:
            return state;
    }
}

export default cartReducer;
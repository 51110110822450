import React, { useState, useHook, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Cookies from 'js-cookie';
import { get, post } from "../adapters/index";
import { useTranslation } from "react-i18next";
import "../translations/i18n";

import addCart,{isExist, removeCart, getQty, decrementCart, incrementCart} from '../utility/Cart';
import veg from '../images/veg.png';
import non_veg from '../images/nonveg.png';

import jBox from 'jbox';
import 'jbox/dist/jBox.all.css';

import { cart } from '../actions/index';
import '../styles/detail-page.css';

const ProductView = (params) => {
    const { t } = useTranslation();
    const [addToCart, setAddToCart] = useState(false);
    const [qty, setQty] = useState(0);
    const [cartId, setCartId] = useState(params.data.cart_id);
    const dispatch = useDispatch();

    const remove2Cart = () => {
        removeCart(params.data.id, params.data.name);
        setAddToCart(false);
        dispatch(cart(-1));

        if (Cookies.get('bhUser') != undefined && Cookies.get('bhCartId') != undefined) {
            const form = new FormData();
            form.append('butcher_id', Cookies.get('bhId'));
            form.append('cart_id', Cookies.get('bhCartId'));
            form.append('item_id', params.data.cart_item_id);

            post('cart/delete_item', form).then(async function (response) {
                //setItems(response.data.data);
            }).catch(function (error) {

            });
        }
    }

    useEffect(function () {
        setAddToCart(isExist(params.data.id));
        setQty(getQty(params.data.id));

        new jBox('Tooltip', {delayOpen: 100,delayClose: 100,attach: '.tooltip3', pointer: 'right:60',onCreated: function () {
            this.setContent("<div></div>");
          }, getTitle: 'data-jbox-title'});

        if (Cookies.get('bhCartId') == undefined && Cookies.get('bhUser') != undefined) {
            get('cart/id').then(async function (response) {
                if (response.data.data != 0)
                    Cookies.set('bhCartId', response.data.data, { expires: 2 });
            }).catch(function (error) {});
        }

    }, [])

    const add2Cart = (productId, name) => {
        addCart(productId, name);
        setAddToCart(true);
        dispatch(cart(1));
        setQty(1);

        if (Cookies.get('bhUser') != undefined) {
            const form = new FormData();
            form.append('butcher_id', Cookies.get('bhId'));
            form.append('product_id', productId);
            form.append('product_qty', 1);

            post('cart/store', form).then(async function (response) {
                if (Cookies.get('bhCartId') == undefined) {
                    get('cart/id').then(async function (response) {
                        if (response.data.data != 0)
                            Cookies.set('bhCartId', response.data.data, { expires: 2 });
                    }).catch(function (error) {});
                }
            }).catch(function (error) {

            });
        }
    }

    const seo_url = (url) => {
        return url.toString()               // Convert to string
            .normalize('NFD')               // Change diacritics
            .replace(/[\u0300-\u036f]/g, '') // Remove illegal characters
            .replace(/\s+/g, '-')            // Change whitespace to dashes
            .toLowerCase()                  // Change to lowercase
            .replace(/&/g, '-and-')          // Replace ampersand
            .replace(/[^a-z0-9\-]/g, '')     // Remove anything that is not a letter, number or dash
            .replace(/-+/g, '-')             // Remove duplicate dashes
            .replace(/^-*/, '')              // Remove starting dashes
            .replace(/-*$/, '');
    }


    const details = () => {
        window.location = '/product/' + params.data.id + '/' + seo_url(params.data.name)
    }

    const minus = () => {
        setQty(prevQty => prevQty - 1);
        decrementCart(params.data.id, 1);

        if (Cookies.get('bhUser') != undefined) {
            const form = new FormData();
            form.append('butcher_id', Cookies.get('bhId'));
            form.append('cart_id', cartId);
            form.append('item_id', params.data.id);
            form.append('quantity', 1);

            post('cart/decrement', form).then(async function (response) {
                //setItems(response.data.data);
            }).catch(function (error) {

            });
        }
    }

    const increment = () => {
        setQty(prevQty => prevQty + 1);
        incrementCart(params.data.id, 1);

        if (Cookies.get('bhUser') != undefined) {
            const form = new FormData();
            form.append('butcher_id', Cookies.get('bhId'));
            form.append('cart_id', cartId);
            form.append('item_id', params.data.id);
            form.append('quantity', 1);

            post('cart/increment', form).then(async function (response) {
                //setItems(response.data.data);
            }).catch(function (error) {

            });
        }
    }

    

    return (
        <div class="list_home">
            <ul>
                <li>
                    <a href="javascript:void(0)" style={{ cursor: 'auto' }}>
                        <figure>

                            {
                                params.data.image != undefined
                                ?
                                <img onClick={(e) => details()} title={params.data.name} style={{ cursor: 'pointer' }} src={process.env.REACT_APP_IMG_URL + params.data.image.replace('/images', '/images/thumbnail')} data-src={process.env.REACT_APP_IMG_URL + params.data.image} alt={params.data.name} class="lazy" />
                                :
                                null
                            }
                            
                        </figure>
                        <div class="score"><strong>{params.data.rating}</strong></div>
                        <em>Italian </em>
                        <h3 onClick={(e) => details()} style={{ cursor: 'pointer', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} title={params.data.name}>
                            <span>
                                {
                                    params.data.type == 1
                                        ?
                                        <img src={veg} alt="" style={{ width: 15, transform: 'none' }} />
                                        :
                                        <img src={non_veg} alt="" style={{ width: 15, transform: 'none' }} />
                                }

                            </span>
                            {" " + params.data.name + " " + params.data.qty_type}
                        </h3>
                        <span style={{ marginTop: 4, display: 'block' }}><b>{t('currency_symbol')} {params.data.unit_price}</b></span>
                        {
                            params.data.stock > 0
                            ?
                            <ul>
                                <li>
                                                    
                                    <span style={{display: addToCart == false ? 'block' : 'none' , cursor: 'pointer'}} class="ribbon off" onClick={() => add2Cart(params.data.id, params.data.name)}>
                                        {t('add')}
                                    </span>

                                    {
                                        addToCart === true && qty > 0
                                        ?
                                        <div class="cart-plus-minus" style={{marginTop:4, float: 'left'}}>
                                            <button onClick={() => minus()} class="dec qtybutton" style={{height: 28}}>-</button>
                                            <input readOnly class="cart-plus-minus-box" type="text" value={qty} style={{width: 40,float: 'left',marginLeft: 4,marginRight: 4, border: '1px solid #e5e5e5',height: 28, textAlign: 'center'}}/>
                                            <button onClick={() => increment()} class="inc qtybutton" style={{height: 28}}>+</button>
                                        </div>
                                        :
                                        null
                                    }
                                    

                                    <span onClick={(e) => remove2Cart()} data-jbox-title={t('areyou')} style={{display: addToCart == true ? 'block' : 'none', cursor: 'pointer', float: 'left', marginLeft: 10, marginTop: 10}} className="tooltip3"><span style={{fontSize:14}} class="fs1" aria-hidden="true" data-icon=""></span> </span> 
                                    
                                </li>
                            </ul>
                            :
                            <span style={{color: '#f30'}}>{t('out_stock')}</span>
                        }
                        
                    </a>
                </li>
            </ul>
        </div>
    );
}


export default ProductView;
import React, { useState, useEffect, useRef, useParams } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Header from "../components/Header"
import Footer from "../components/Footer"
import '../styles/detail-page.css';
import Cookies from 'js-cookie';
import { post } from "../adapters/index";
import { useTranslation } from "react-i18next";
import "../translations/i18n";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import CartList from '../components/CartList'
import { reactLocalStorage } from 'reactjs-localstorage';
import Checkout from './Checkout';
import { openLogin, openLoginAfter } from '../actions/index';
import PulseLoader from 'react-spinners/PulseLoader';

const Category = (props) => {

    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(0);
    const { t } = useTranslation();

    useEffect(function () {
        var c = window.client;
        if (Cookies.get('bhId') == undefined) {
            Cookies.set('bhId', process.env.REACT_APP_DEFAULT_ID, { expires: 2 });
        }
        _loadCategoryProducts();
    }, []);

    const _loadCategoryProducts = () => {
        setLoading(1);
        const form = new FormData();
        form.append('butcher_id', Cookies.get('bhId'));
        form.append('category_id', props.match.params.id);

        post('wb/category/products', form).then(async function (response) {
            setProducts(response.data.data.products);
            setLoading(2);
        }).catch(function (error) {
        });
    }

    const seo_url = (url) => {
        return url.toString()               // Convert to string
            .normalize('NFD')               // Change diacritics
            .replace(/[\u0300-\u036f]/g, '') // Remove illegal characters
            .replace(/\s+/g, '-')            // Change whitespace to dashes
            .toLowerCase()                  // Change to lowercase
            .replace(/&/g, '-and-')          // Replace ampersand
            .replace(/[^a-z0-9\-]/g, '')     // Remove anything that is not a letter, number or dash
            .replace(/-+/g, '-')             // Remove duplicate dashes
            .replace(/^-*/, '')              // Remove starting dashes
            .replace(/-*$/, '');
    }

    return (
        <div>
            <Header />
            <main>
                <div class="page_header element_to_stick">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-8 col-lg-7 col-md-7 d-none d-md-block">
                                
                                <h1><span style={{textTransform:'capitalize'}}>{props.match.params.name}</span> {t('products')}</h1>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="container margin_30_40">
                    <div class="row">

                        {
                            loading === 1
                            ?
                            <div class="col-lg-12 text-center">
                                <PulseLoader color="#83c55e" size={8} />
                            </div>
                            :
                            <></>
                        }

                        {
                            loading === 2 && products.length === 0
                            ?
                            <div class="col-lg-12 text-center">
                                <span>{t('category_product_not')}</span>
                            </div>
                            :
                            <></>
                        }
                        
                        <div class="col-lg-12">
                            <div class="row">
                                {
                                    products.map((p, k) =>
                                        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                                            <div class="strip">
                                                <figure>
                                                    <img style={{top: '57%',height: 217}} src={process.env.REACT_APP_IMG_URL + p.image} data-src={process.env.REACT_APP_IMG_URL + p.image} class="img-fluid lazy" alt="" />
                                                    <a href={"/product/" + p.id + "/" + seo_url(p.name + '-' + p.qty_type)} class="strip_info">
                                                        <div class="item_title">
                                                            <h3>{p.name} {p.qty_type}</h3>
                                                        </div>
                                                    </a>
                                                </figure>
                                                <ul>
                                                    <li><span>{p.unit_price} Euros</span></li>
                                                    <li>
                                                        <div class="score"><span><em>{p.num_ratings} Ratings</em></span><strong>{p.ratings}</strong></div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    )
                                }

                            </div>
                            <div class="pagination_fg" style={{display: 'none'}}>
                                <a href="#">&laquo;</a>
                                <a href="#" class="active">1</a>
                                <a href="#">2</a>
                                <a href="#">3</a>
                                <a href="#">4</a>
                                <a href="#">5</a>
                                <a href="#">&raquo;</a>
                            </div>
                        </div>
                    </div>
                </div>

            </main>
            <Footer />
        </div>
    )
}

export default Category;
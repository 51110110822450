import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Header from "../components/Header"
import Footer from "../components/Footer"
import '../styles/css_skelton.css';
import Cookies from 'js-cookie';
import { post, get } from "../adapters/index";
import { useTranslation } from "react-i18next";
import "../translations/i18n";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import CartList from '../components/CartList'
import { reactLocalStorage } from 'reactjs-localstorage';

import i18n from '../translations/i18n';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import addCart from '../utility/Cart';
import axios from "axios";
import moment from 'moment';
//import 'moment/locale/en';

import { store } from 'react-notifications-component';

const Checkout = () => {

    const { t } = useTranslation();
    const [address, setAddress] = useState([]);
    const [dates, setDeliveryDates] = useState([]);
    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);
    const [pincodeValid, setPincodeValid] = useState('invalid');
    const [deliveryDate, setDeliveryDate] = useState('');
    const [deliveryTimeSlot, setDeliveryTimeSlot] = useState('');
    const [deliveryPincode, setDeliveryPincode] = useState(0);
    const [deliveryId, setDeliveryId] = useState(0);
    const [items, setItems] = useState([]);
    const [deliveryCharge, setDeliveryCharge] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [total, setTotal] = useState(0);
    const [cartId, setCartId] = useState(0);
    const [checkout, setCheckout] = useState(false);

    
    useEffect(function () {
        if (cartId != 0 && deliveryTimeSlot != '' && deliveryPincode != 0 && deliveryId != 0) {
            //updateDeliveryDetails(cartId, deliveryTimeSlot, deliveryPincode, deliveryId);
        }

    }, [address, cartId, deliveryPincode, deliveryId]);
    
    useEffect(function () {
        //moment.locale('fr');
        var dd = moment().add(1,'days').format('YYYY-MM-DD').toString();
        setDeliveryDate(dd)

        if (Cookies.get('bhUser') != undefined) {
            fetchUserCartData();
            fetchDeliveryDates();
            fetchAddress();
        }
        else {
            window.location = '/';
        }
    }, []);


    const updateDeliveryDetails = (cartId, deliveryTimeSlot, deliveryPincode, deliveryId) => {
        const form = new FormData();

        form.append('butcher_id', Cookies.get('bhId'));
        form.append('cart_id', cartId);
        form.append('delivery_date', deliveryDate);
        form.append('delivery_timeslot', deliveryTimeSlot);
        form.append('pincode', deliveryPincode);
        form.append('delivery_id', deliveryId);

        post('cart/save_delivery_date_pincode', form).then(async function (response) {
            setDeliveryCharge(response.data.delivery_charge);
            setTotal(response.data.total);
            setCheckout(response.data.checkout);
        }).catch(function (error) {});
    }

    const fetchDeliveryCharge = (pincode, cartId) => {
        //alert(pincode);
        //alert(cartId)
    }

    const fetchDeliveryDates = () => {
        get('delivery_dates').then(async function (response) {
            setDeliveryDates(response.data.data[0].timeslots);
            setDeliveryTimeSlot(response.data.data[0].timeslots[0]);
        }).catch(function (error) {});
    }

    const fetchAddress = () => {
        get('customer/address-list').then(async function (response) {
            setAddress(response.data.data.customeraddress);

            if (response.data.data.customeraddress.length == 0) {
                onOpenModal();
            }
            else {
                fetchDeliveryCharge(response.data.data.customeraddress[0].pincode, cartId);
                setDeliveryPincode(response.data.data.customeraddress[0].pincode);
                setDeliveryId(response.data.data.customeraddress[0].id);
                onCloseModal();
            }
        }).catch(function (error) {});
    }

    const fetchUserCartData = () => {
        const form = new FormData();
		form.append('butcher_id', Cookies.get('bhId'));

		post('cart/wb/checkout_view', form).then(async function (response) {

            if (response.data.data.length == 0) {
                window.location = '/';
            }


			setItems(response.data.data);
            setTotal(response.data.total_cart_price);
            setCartId(response.data.data[0].cart_id);
            setDeliveryCharge(response.data.delivery_charge);
            setCheckout(response.data.checkout);
		}).catch(function (error) {
            window.location = '/';
        });
    }

    const validatePincode = async (pincode) => {
        //customer/validate/pincode

        /*const form = new FormData();
        form.append("pincode", pincode);
        post('customer/validate/pincode', form).then(async function (response) {
            setPincodeValid(...response.data.data.pincode);
        }).catch(function (error) {});*/

        const form = new FormData();
        form.append("pincode", pincode);

        const options = {
            method: 'POST',
            url: process.env.REACT_APP_API_URL + 'customer/validate/pincode',
            headers: {
                'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
                Accept: 'application/json',
                Authorization: 'Bearer ' + Cookies.getJSON('bhUser')
            },
            data: form
        };

        await axios.request(options).then(function (response) {
            setPincodeValid(...response.data.data.pincode);
        }).catch(function (error) {
            console.error(error);
        });

    }

    const alerts = (msg) => {
        store.addNotification({
            title: 'Alerte',
            message: msg,
            type: "danger",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
    }

    Element.prototype.insertAfter = function(newEl) {
        this.parentNode.insertBefore(newEl, this.nextSibling);
    }

    const add = () => {

        var error = false;
        var ele = document.querySelectorAll(".error");
        
        for (var j = 0; j < ele.length; j++) {
            ele[j].remove();
        }

        const newEl = document.createElement("div");
        newEl.classList.add('error');

        var first_name = document.getElementById('first_name');
        var last_name = document.getElementById('last_name');
        var phone = document.getElementById('phone');
        var address1 = document.getElementById('address1');
        var address2 = document.getElementById('address2');
        var address3 = document.getElementById('address3');

        var ville = document.getElementById('ville');
        var land_mark = document.getElementById('land_mark');
        var pincode = document.getElementById('pincode');

        if (first_name.value.length == 0) {
            var newEll = document.createElement("div");
            newEll.classList.add('error');
            newEll.innerHTML = "Entrez s'il vous plait prénom";
            first_name.insertAfter(newEll);
            error = true;
        }
        
        if (last_name.value.length == 0) {
            var newEll = document.createElement("div");
            newEll.classList.add('error');
            newEll.innerHTML = "Entrez s'il vous plait nom de famille";
            last_name.insertAfter(newEll);
            error = true;
        }
        if (phone.value.length == 0) {
            var newEll = document.createElement("div");
            newEll.classList.add('error');
            newEll.innerHTML = "Entrez s'il vous plait numéro de portable";
            phone.insertAfter(newEll);
            error = true;
        }
        if (address1.value.length == 0) {
            var newEll = document.createElement("div");
            newEll.classList.add('error');
            newEll.innerHTML = "Entrez s'il vous plait adresse ligne 1";
            address1.insertAfter(newEll);
            error = true;
        }
        if (address2.value.length == 0) {
            var newEll = document.createElement("div");
            newEll.classList.add('error');
            newEll.innerHTML = "Entrez s'il vous plait adresse ligne 2";
            address2.insertAfter(newEll);
            error = true;
        }
        if (address3.value.length == 0) {
            var newEll = document.createElement("div");
            newEll.classList.add('error');
            newEll.innerHTML = "Entrez s'il vous plait adresse ligne 3";
            address3.insertAfter(newEll);
            error = true;
        }
        if (ville.value.length == 0) {
            var newEll = document.createElement("div");
            newEll.classList.add('error');
            newEll.innerHTML = "Entrez s'il vous plait ville";
            ville.insertAfter(newEll);
            error = true;
        }
        if (land_mark.value.length == 0) {
            var newEll = document.createElement("div");
            newEll.classList.add('error');
            newEll.innerHTML = "Entrez s'il vous plait point de repère";
            land_mark.insertAfter(newEll);
            error = true;
        }
        if (pincode.value.length == 0) {
            var newEll = document.createElement("div");
            newEll.classList.add('error');
            newEll.innerHTML = "Entrez s'il vous plait code postal";
            pincode.insertAfter(newEll);
            error = true;
        }

        if (error === true) {
            return false;
        }

        const form = new FormData();
        form.append("pincode", pincode.value);
        post('customer/validate/pincode', form).then(async function (response) {
            if (response.data.data.pincode == 'invalid') {
                alerts("Désolé, cet article ne peut pas être expédié à l'adresse que vous avez sélectionnée")
                return false;
            }
            else {
                var getSelectedValue = document.querySelector( 'input[name="type"]:checked'); 

                const form = new FormData();
                form.append("deliver_to_fname", first_name.value);
                form.append("deliver_to_lname", last_name.value);
                form.append("deliver_to_phone", phone.value);
                form.append("address_line1", address1.value);
                form.append("address_line2", address2.value);
                form.append("address_line3", address3.value);
                form.append("landmark", land_mark.value);
                form.append("city", ville.value);
                form.append("pincode", pincode.value);
                form.append("type", getSelectedValue.value);

                post('customer/add-address', form).then(async function (response) {
                    fetchAddress();
                }).catch(function (error) {});
            }
        }).catch(function (error) {});
    }

    const addAddress = () => {
        onOpenModal();
    }

    const changeLabel = (e, id, pincode) => {

        var ele = document.querySelectorAll(".dd");
        
        for (var j = 0; j < ele.length; j++) {
            ele[j].classList.remove("active");
        }

        if (e.target.localName !== 'label') {
            if (e.target.parentElement.localName === 'label') {
                e.target.parentElement.classList.add("active");
            }
        } 
        else {
            e.target.classList.add("active");
        }


        updateDeliveryDetails(cartId, deliveryTimeSlot, pincode, id);
    }

    const changeLabelTi = (e, timeslot) => {
        var ele = document.querySelectorAll(".timeslot");
        
        for (var j = 0; j < ele.length; j++) {
            ele[j].classList.remove("active");
        }

        if (e.target.localName !== 'label') {
            if (e.target.parentElement.localName === 'label') {
                e.target.parentElement.classList.add("active");
            }
        } 
        else {
            e.target.classList.add("active");
        }

        updateDeliveryDetails(cartId, timeslot, deliveryPincode, deliveryId);
    }

    const placeOrder = () => {
        var getSelectedValue = document.querySelector( 'input[name="delivery_timeslot"]:checked');
        if (getSelectedValue === null) {
            //Please select time slot
            alerts('Veuillez sélectionner une plage horaire');
            return false;
        }

        var getSelectedAddressValue = document.querySelector( 'input[name="delivery_address"]:checked');
        if (getSelectedAddressValue === null) {
            //Please select delivery address
            alerts("Veuillez sélectionner l'adresse de livraison");
            return false;
        }

        var timeSlot = getSelectedValue.value;
        var address = getSelectedAddressValue.value;

        ///order/store/

        const form = new FormData();
		form.append('butcher_id', Cookies.get('bhId'));
        form.append('cart_id', cartId);
        form.append('coupon_id', 0);
        form.append('shipping_id', address);
        form.append('distance', 5);
        form.append('order_from', 'web')

		post('order/store', form).then(async function (response) {
			reactLocalStorage.remove("bhCart");
            window.location = response.data.data;
		}).catch(function (error) {});

    }

    return (
        <div>
            <Header />

            <main class="bg_gray pattern">
                <div class="container margin_60_40">
                    <div class="row justify-content-center">
                        <div class="col-xl-6 col-lg-8" style={{ display: open == false ? 'block' : 'none' }}>

                            <div className="checkout">
                                <div className="head">
                                    {t('delivery_address')} 
                                    <a href="javascript:void(0);" onClick={(e) => addAddress()}>+ {t('add_address')}</a>
                                </div>

                                <div className="row">

                                    {
                                          address.map((a, k) =>
                                            
                                            <div className="col-sm-4">
                                                <label style={{cursor: 'pointer'}} onClick={(e) => changeLabel(e, a.id, a.pincode)} htmlFor={a.id + "DA"} className={k === 0 ? "card active dd" : "card dd"}>
                                                    <div className="address-type">{a.type}</div>
                                                    <div>
                                                        {a.address_line1} {a.address_line2} {a.address_line3}<br/> {a.landmark}<br/>
                                                        {a.city} {a.pincode}
                                                    </div>
                                                </label>
                                                <input id={a.id + "DA"} defaultChecked={k === 0 ? true : false} type="radio" name="delivery_address" value={a.id} style={{opacity:0}} />
                                            </div>
                                        )
                                    }

                                </div>
                            </div>


                            <div className="checkout">
                                <div className="head">
                                    {t('delivery_schedule')}
                                </div>

                                <div className="row">

                                {
                                    dates.map((d, k) => 
                                        <div className="col-sm-4">
                                            <label style={{cursor: 'pointer'}} onClick={(e) => changeLabelTi(e, d)} htmlFor={k + "DT"} className={k === 0 ? "card timeslot" : "card timeslot"}>
                                                <div className="address-type">{d}</div>
                                                <div>
                                                    {d}
                                                </div>
                                            </label>
                                            <input id={k + "DT"} type="radio" name="delivery_timeslot" value={d} style={{opacity:0}} />
                                        </div>
                                    )
                                }

                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-8 card active" style={{ display: open == true ? 'block' : 'none' }}>

                            <div className="row">
                                <div className="col-sm-12 col-xl-12 col-lg-12" style={{ marginTop: 10, marginBottom: 10 }}>
                                    <h3>{t('add_address')}</h3><hr />
                                </div>
                                <div className="col-sm-12 col-xl-4 col-lg-4">
                                    <div className="form-group">
                                        <label>Prénom</label>
                                        <input type="text" className="form-control" name="first_name" id="first_name" />
                                    </div>
                                </div>
                                <div className="col-sm-12 col-xl-4 col-lg-4">
                                    <div className="form-group">
                                        <label>Nom de famille</label>
                                        <input type="text" className="form-control" name="last_name" id="last_name" />
                                    </div>
                                </div>
                                <div className="col-sm-12 col-xl-4 col-lg-4">
                                    <div className="form-group">
                                        <label>Numéro de portable</label>
                                        <input type="text" className="form-control" name="phone" id="phone" />
                                    </div>
                                </div>

                                <div className="col-sm-12 col-xl-12 col-lg-12" style={{ marginTop: 10, marginBottom: 10 }}>
                                    <label>Adresse ligne 1</label>
                                    <input type="text" className="form-control" name="address1" id="address1" />
                                </div>

                                <div className="col-sm-12 col-xl-12 col-lg-12" style={{ marginTop: 10, marginBottom: 10 }}>
                                    <label>Adresse ligne 2</label>
                                    <input type="text" className="form-control" name="address2" id="address2" />
                                </div>

                                <div className="col-sm-12 col-xl-12 col-lg-12" style={{ marginTop: 10, marginBottom: 10 }}>
                                    <label>Adresse ligne 3</label>
                                    <input type="text" className="form-control" name="address3" id="address3" />
                                </div>


                                <div className="col-sm-12 col-xl-4 col-lg-4">
                                    <div className="form-group">
                                        <label>Ville</label>
                                        <input type="text" className="form-control" name="ville" id="ville" />
                                    </div>
                                </div>
                                <div className="col-sm-12 col-xl-4 col-lg-4">
                                    <div className="form-group">
                                        <label>Point de repère</label>
                                        <input type="text" className="form-control" name="land_mark" id="land_mark" />
                                    </div>
                                </div>
                                <div className="col-sm-12 col-xl-4 col-lg-4">
                                    <div className="form-group">
                                        <label>Code Postal</label>
                                        <input type="text" className="form-control" name="pincode" id="pincode" />
                                    </div>
                                </div>

                                <div className="col-sm-12 col-xl-4 col-lg-4">
                                    <input checked type="radio" name="type" value="home" id="maison"/> <label htmlFor="maison"> MAISON </label>
                                </div>

                                <div className="col-sm-12 col-xl-4 col-lg-4">
                                    <input type="radio" name="type" value="work" id="travail"/> <label htmlFor="travail">TRAVAIL</label>
                                </div>

                                <div className="col-sm-12 col-xl-4 col-lg-4">
                                    <input type="radio" name="type" value="other" id="autre"/> <label htmlFor="autre">AUTRE</label>
                                </div>

                                <div className="col-sm-12 col-xl-12 col-lg-12" style={{ marginTop: 10, marginBottom: 10 }}>
                                <a href="javascript:void(0);" class="btn_1 full-width mb_5" onClick={() => add()}>Soumettre</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4" id="sidebar_fixed">
                            <div class="box_booking">
                                <div class="head">
                                    <h3>{t('order_summary')}</h3>
                                    <div>Pizzeria da Alfredo</div>
                                </div>
                                <div class="main">
                                    <ul>
                                        <li>Date<span>{deliveryDate}</span></li>
                                    </ul>
                                    <hr />
                                    <ul class="clearfix">
                                        {
                                            items.map((i, k) =>
                                                <li><a href="#0">{i.product_total_qty}x {i.product_name}</a><span>{t('currency_symbol')} {i.product_price}</span></li>
                                            )
                                        }
                                    </ul>

                                    <ul class="clearfix">
                                        <li style={{display: 'none'}}>Subtotal<span>{t('currency_symbol')} {subTotal}</span></li>
                                        <li>{t('delivery_charge')}<span>{t('currency_symbol')} {deliveryCharge}</span></li>
                                        <li class="total">{t('total_price')}<span>{t('currency_symbol')} {total}</span></li>
                                    </ul>

                                    {
                                        checkout === true 
                                        ?
                                        <a href="javascript:void(0);" onClick={() => placeOrder()} class="btn_1 full-width mb_5">{t('place_order')}</a>
                                        :
                                        <div style={{padding: 10,border: '1px solid #bdb2b2'}}>
                                            <span style={{display:'none'}}>you must have a minimum order  amount of 100 to place your order.Your current order total is 60</span>
                                            vous devez avoir un montant de commande minimum de 100 pour passer votre commande. Le total de votre commande actuelle est de 60
                                        </div>
                                    }

                                    
                                    <div class="text-center"></div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </main>
            <Footer />
        </div>
    )
}

export default Checkout;